import styled from "@emotion/styled";
import { DocumentErrorIcon } from "@assets/icons/document-error.svg";
import { Button } from "components/button";
import React, { useCallback, useState } from "react";
import { createModal, ModalCardContainer, ModalCloseIcon } from "services/modal-service";
import { colors, row, sizes, stack } from "style";
import { useI18n } from "utils/i18n";
import {motion} from "framer-motion";
import { CheckboxMultiSelect } from "../inputs/multi-select-input";
import { commonService, useCCAMPractitionerCommon } from "../../services/common-service";
import { SearchCCAMModal } from "./search-ccam-modal";
import { useUser } from "../../services/auth-service";

interface ConfirmModalProps {
	onClose: (status: string | null) => void;
	practitionerId: string;
	dlConnectorId: string;
}

export const AddDocCustomToAnotherCodeModal: React.FC<ConfirmModalProps> = (
	{
		onClose,
		practitionerId,
		dlConnectorId,
	}) => {
	const { format } = useI18n();
	const [loading, setLoading] = useState(false);
	const [ccamValue, setCcamValue] = useState<string[]>([]);
	const { ccamList } = useCCAMPractitionerCommon(practitionerId)
	const user = useUser()

	const save = useCallback(async ()=>{
		try{
			setLoading(true)
			await commonService.addDocCustom(practitionerId, dlConnectorId, ccamValue)
		}catch(e: any){
			throw e.message
		}
		finally {
			setLoading(false)
			onClose?.(null)
		}
	},[ccamValue, dlConnectorId, practitionerId])
	return (

			<ModalContainer>
				<form
					onSubmit={e => {
						e.preventDefault();
						save()
					}}
				>
				<ModalCloseIcon onClick={() => onClose(null)} />
				<Content>
					<DocumentsMessageContainer>
						<ColorDocumentErrorIcon color={colors.orange} />
					<Title>
						{format( "patient.trusted.person.modal.title")}
					</Title>
					</DocumentsMessageContainer>
					<CheckboxMultiSelect<string>
						innerId="CCAM"
						values={ccamValue??[]}
						onChange={(e) => setCcamValue(e)}
						defaultValue="Sélectionner un code CCAM"
						options={ccamList
							?.sort((codeA, codeB) => codeA.codeCCAM.localeCompare(codeB.codeCCAM))
							.map(code => code.id) ?? []}
						itemRenderer={id => `${ccamList?.find(code => code.id === id)?.codeCCAM}` ?? ""}
						itemNumber={0}
					/>
					<LinkButton
						onClick={async () => {
							await createModal<string | null>(({ onClose }) => (
								<SearchCCAMModal onClose={onClose} practitionerId={user?.type === 'practitioner' ? undefined : practitionerId as string} />
							));
						}}
					>
						{format("surgerySection.searchCCAM.link")}
					</LinkButton>
					<Text>
						{format("confirmAddDocToAnotherCode")}
					</Text>
					<Buttons>
						<Button
							loading={loading}
							type="submit"
						>
							{format("patient.trusted.person.modal.dismiss.confirmation.button")}
						</Button>
						<Button secondary onClick={()=>onClose("dissmissall")}>
							{format( "patient.trusted.person.modal.dismiss.cancel.button")}
						</Button>
					</Buttons>
				</Content>
				</form>
			</ModalContainer>
	);
};


const ModalContainer = styled(ModalCardContainer)`
    padding: 43px 40px;
    width: 30%;
    height: 50%;
    ${stack("S")};
    @media (max-width: 920px) {
        padding: ${sizes.L};
    }
	${row(0, "center", "center")};
`;

const DocumentsMessageContainer = styled.div`
	${row("S", "flex-start", "flex-start")};
`;

const ColorDocumentErrorIcon = styled(DocumentErrorIcon)<{ color: string }>`
	width: 32px;
	height: 32px;
	path {
		fill: ${props => props.color};
	}
`;
const Content = styled.div`
	${stack("M")};
		width: 90%;
`;
const Title = styled(motion.div)`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;

const Buttons = styled.div`
	${row("S", "flex-end", "flex-end")}
`;

const Text = styled(motion.span)<{ textColor?: string }>`
	font-size: 13px;
	color: ${props => props.textColor?? "black"};
`;

const LinkButton = styled.div`
	display: inline-block;
	background-color: transparent;
	color: black;
	text-decoration: underline;
	cursor: pointer;
	&:hover {
		color: ${colors.pink};
	}
`;