import { XHRUploadOptions } from "@uppy/xhr-upload";
import { Document, DocumentUpdateDTO, RejectedDocument } from "domain/document";
import { Patient } from "domain/patient";
import {
	CancerType,
	CCAM,
	DocCustom,
	HealthcareProviderAgency,
	Module,
	PostOperative,
	PostOperativeUpdateDTO,
	Practitioner,
	Secretary,
	Surgery,
	SurgeryStatus,
	SurgeryUpdateDTO,
} from "domain/surgery";
import { Map } from "immutable";
import { useComputedObservable, useObservable, WritableObservable } from "micro-observables";
import { useCallback, useEffect, useMemo, useState } from "react";
import { authService, useUser } from "services/auth-service";
import { Paginated, PaginationOptions, SortOption } from "utils/pagination";
import { isDateSameDay } from "utils/time";
import { api, apiConfig, buildPaginationParams, buildSearchParams } from "./api";
import { useParams } from "react-router";
import { CredentialsUpdate } from "../domain/utils";
import { DocCustomDTO, practitionerService } from "./practitioner-service";
import { UserProfile } from "../domain/user-profile";
import {
	DocCustomLight,
	InputOptam,
	PractitionerAmount,
	PractitionerContact,
	SurgeryWithItsHpu,
} from "../domain/practitioner";
import {CustomDpm} from "../components/sections/form-field-section-secretary";
import { AxiosResponse } from "axios";
import { FormParad } from "../domain/formParad";
import { SurgeryHPU } from "../domain/hpu";

export const PATIENT_PAGE_SIZE = 25;
export const SURGERY_PAGE_SIZE = 25;
export const FIRST_PATIENT_PAGE = { page: 0, size: PATIENT_PAGE_SIZE };
export const FIRST_SURGERY_PAGE = { page: 0, size: SURGERY_PAGE_SIZE };

export type PatientCreationDTO = Pick<Patient, "firstName" | "lastName" | "birthDate" | "email" | "phoneNumber1"> & {
	legalGuardian1Id?: string | null;
	legalGuardian2Id?: string | null;
	// email?: string;
	assign: boolean;
	addTutorAfter?: boolean | null;
};

export class SecretaryService {
	profile = new WritableObservable<Secretary | null>(null);
	public practitioners = new WritableObservable<Practitioner[] | null>(null);
	practitioner = new WritableObservable<Practitioner | null>(null);
	patients = new WritableObservable<Map<number, Paginated<Patient>>>(Map());
	surgeries = new WritableObservable<Map<number, Paginated<Surgery>>>(Map());
	surgeryByIds = new WritableObservable<Map<string, Surgery>>(Map());
	patientByIds = new WritableObservable<Map<string, Patient>>(Map());
	surgeriesByPatientId = new WritableObservable<Map<string, Surgery[]>>(Map());
	postOperativesBySurgeryId = new WritableObservable<Map<string, PostOperative[]>>(Map());
	modulesByPractitionerId = new WritableObservable<Map<string, Module[]>>(Map());
	healthcareProviderBySecretaryId = new WritableObservable<Map<string, HealthcareProviderAgency[]>>(Map());
	public secretaryCredentials = new WritableObservable<{
		newPassword: string | undefined;
		newPasswordConfirmation: string | undefined;
		currentPassword: string | undefined;
	} | null>(null);
	public secretary = new WritableObservable<Secretary | null>(null);
	docCustoms = new WritableObservable<DocCustom[]>([]);
	ccams = new WritableObservable<CCAM[]>([]);
	cancerTypes = new WritableObservable<CancerType[]>([]);
	ccam = new WritableObservable<CCAM | null>(null);
	countSurgeriesWithHpus = new WritableObservable<number | null>(null);
	formParad = new WritableObservable<FormParad | null>(null);
	practitionerAmounts = new WritableObservable<PractitionerAmount[]>([]);
	practitionerContacts = new WritableObservable<PractitionerContact[]>([]);
	practitionerDocuments = new WritableObservable<DocCustomLight[]>([]);
	docCustomsPagined = new WritableObservable<Map<number, Paginated<DocCustom>>>(Map());
	async fetchProfile(): Promise<Secretary> {
		const profile = await api.get<Secretary>("secretary/me");
		this.profile.set(profile.data);
		return profile.data;
	}

	async signUp(firstName: string, lastName: string, idCode: string, login: string, password: string) {
		const result = await api.post(`secretary/register`, { login, firstName, lastName, password });
		return result.data;
	}

	async fetchPractitioner(practitionerId: string): Promise<Practitioner> {
		const practitionerTab = await api.get<Practitioner>(`/secretary/practitioner/${practitionerId}`);
		this.practitioner.update(() => practitionerTab.data);
		return practitionerTab.data;
	}

	async fetchPractitioners(): Promise<Practitioner[]> {
		const practitionerTab = await api.get<Practitioner[]>("/secretary/practitioners");
		const allPractitioner = practitionerTab.data;
		this.practitioners.set(allPractitioner);
		return allPractitioner;
	}

	async fetchPatients(
		pageOptions: PaginationOptions,
		practitionerId: string,
		sortOptions?: SortOption[] | undefined,
		search?: string | undefined
	): Promise<void> {
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
		};
		const params = buildSearchParams(paramsObject, sortOptions);

		const patientPage = await api.get<Paginated<Patient>>(`/secretary/practitioner/${practitionerId}/patients`, {
			params,
		});
		this.patients.update(patientPages => patientPages.set(patientPage.data.page, patientPage.data));
	}

	async fetchPatient(patientId: string, practitionerId: string): Promise<void> {
		const patient = await api.get<Patient>(`secretary/practitioner/${practitionerId}/patient/${patientId}`);
		this.patientByIds.update(patientMap => patientMap.set(patient.data.id, patient.data));
	}

	async fetchSurgery(surgeryId: string, practitionerId: string): Promise<void> {
		const surgery = await api.get<Surgery>(`/secretary/practitioner/${practitionerId}/surgery/${surgeryId}`);
		this.surgeryByIds.update(surgeryMap => surgeryMap.set(surgery.data.id, surgery.data));
	}

	async fetchSurgeries(
		pageOptions: PaginationOptions,
		practitionerId: string,
		sortOptions?: SortOption[],
		search?: string,
		active?: boolean,
		future?: boolean
	): Promise<void> {
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
			...(future ? { future: 1 } : {}),
			...(active ? { status: SurgeryStatus.Active } : {}),
		};
		const params = buildSearchParams(paramsObject, sortOptions);

		const surgeryPage = await api.get<Paginated<Surgery>>(`secretary/practitioner/${practitionerId}/surgeries`, {
			params,
		});
		this.surgeries.update(surgeryPages => surgeryPages.set(surgeryPage.data.page, surgeryPage.data));
	}

	async fetchPatientSurgeries(patientId: string, practitionerId: string): Promise<Surgery[]> {
		const pageOptions = { page: 0, pageOptions: 100 };
		const sortOption: SortOption = { by: "surgeryDate", order: "ASC" };

		const patientSurgeryPage = await api.get<Paginated<Surgery>>(`secretary/practitioner/${practitionerId}/surgeries`, {
			params: { ...buildPaginationParams(pageOptions, sortOption), patientId },
		});
		this.surgeriesByPatientId.update(surgeriesMap => surgeriesMap.set(patientId, patientSurgeryPage.data.content));
		return patientSurgeryPage.data.content;
	}

	async createSurgery(
		practitionerId: string,
		patientId: string,
		hospitalId: string,
		surgeryDate: string,
		visitType: string,
		classificationCode: string,
		laterality: string,
		mutual: string,
		healthcareProviderId: string | null,
		anesthesiaType: string,
		visitStartDate: string | null,
		visitEndDate: string | null,
		anesthesiaFormAnswers?: string,
		healthcareProviderReason?: string,
		cancerType?: string,
		cancerStade?: string,
		needAld?: boolean,
		needWorkAccident?:boolean,
		agreementSocialSecurity?: boolean,
	) {
		const newSurgery = await api.post<Surgery>(`/secretary/practitioner/${practitionerId}/surgeries`, {
			patientId,
			hospitalId,
			surgeryDate,
			visitType,
			visitStartDate,
			visitEndDate,
			classificationCode,
			laterality,
			mutual,
			healthcareProviderId,
			anesthesiaType,
			anesthesiaFormAnswers,
			healthcareProviderReason,
			cancerType,
			cancerStade,
			needAld,
			needWorkAccident,
			agreementSocialSecurity
		});
		this.surgeryByIds.update(surgeryMap => surgeryMap.set(newSurgery.data.id, newSurgery.data));
		return newSurgery.data;
	}

	async createPatient(patient: PatientCreationDTO, practitionerId: string | undefined): Promise<Patient> {
		const newPatient = await api.post<Patient>(`/secretary/practitioner/${practitionerId}/patient`, patient);
		this.patientByIds.update(patientMap => patientMap.set(newPatient.data.id, newPatient.data));
		return newPatient.data;
	}
	async sendOnboardingEmailSecretary(patient: Patient) {
		await api.post<Patient>(`secretary/mailing/${patient.id}`);
	}

	async assignPatient(id: string, practitionerId: string | undefined) {
		const patient = await api.post<Secretary>(`/secretary/practitioner/${practitionerId}/patient/assign`, { id });
		return patient.data;
	}

	async getAllPatientByIdentity(firstName: string, lastName: string, birthDate: string): Promise<Patient[]> {
		const patientPage = await api.get<Paginated<Patient>>(`secretary/practitioner/patient/search`, {
			params: { firstName, lastName, birthDate, page: 0, size: 10 },
		});
		return patientPage.data.content;
	}

	async getAllPatientByEmail(email: string): Promise<Patient[]> {
		const patientPage = await api.get<Paginated<Patient>>(`secretary/practitioner/patient/search`, {
			params: { email, page: 0, size: 10 },
		});
		return patientPage.data.content;
	}

	// On logge les actions du praticien sur les documents
	async log(surgeryId: string, documentId: string) {
		await api.get(`/secretary/log/surgery/${surgeryId}/document/${documentId}`);
	}

	async getDocumentUrl(surgeryId: string, practitionerId: string | undefined, documentId: string | undefined, zip = false): Promise<string | Blob | undefined> {
		if (practitionerId && documentId) {
			try {
				const response = await api.get(`/secretary/practitioner/${practitionerId}/surgery/${surgeryId}/document/${documentId}/download`, {
					responseType: 'blob', // Important: Indique que la réponse doit être traitée comme un Blob
				});

				return downloadBlob(response, zip)
			} catch (error) {
				console.error("Erreur lors du téléchargement du document:", error);
			}
		}
	}
	async getDocumentUrlWithoutSurgery(practitionerId: string | undefined, documentId: string | undefined): Promise<string | Blob | undefined> {
		if (practitionerId && documentId) {
			try {
				//URL différente du praticien car la route existe déjà avec une utilisation autre
				const response = await api.get(`/secretary/practitioner/${practitionerId}/document/${documentId}`, {
					responseType: 'blob', // Important: Indique que la réponse doit être traitée comme un Blob
				});

				return downloadBlob(response)
			} catch (error) {
				console.error("Erreur lors du téléchargement du document:", error);
			}
		}
	}

	getDocumentUploadEndpoint(surgeryId: string, practitionerId: string): XHRUploadOptions {
		const user = authService.user.get();
		return {
			endpoint: `${apiConfig.baseURL}/secretary/practitioner/${practitionerId}/surgery/${surgeryId}/document`,
			formData: true,
			method: "post",
			fieldName: "file",
			headers: {
				Authorization: `Bearer ${user?.tokens.accessToken}`,
				...apiConfig.headers,
			},
			timeout: 0,
		} as const;
	}

	async deleteDocument(surgeryId: string, documentId: string, practitionerId: string): Promise<void> {
		await api.delete(`secretary/practitioner/${practitionerId}/surgery/${surgeryId}/document/${documentId}`);
	}

	async updateDocument(
		surgeryId: string,
		documentId: string,
		practitionerId: string,
		update: DocumentUpdateDTO
	): Promise<void> {
		await api.post(`secretary/practitioner/${practitionerId}/surgery/${surgeryId}/document/${documentId}`, update);
	}

	async addWeblink(practitionerId: string, surgeryId: string, url: string, description: string) {
		await api.post(`secretary/practitioner/${practitionerId}/surgery/${surgeryId}/weblink`, {
			url: url,
			description: description,
		});
	}

	async updateSurgery(surgeryId: string, practitionerId: string, update: SurgeryUpdateDTO): Promise<void> {
		const surgery = await api.post<Surgery>(`/secretary/practitioner/${practitionerId}/surgery/${surgeryId}`, update);
		this.surgeryByIds.update(surgeryMap => surgeryMap.set(surgery.data.id, surgery.data));
	}

	async updatePassword(
		secretaryCredentials: CredentialsUpdate | null,
		patch: Partial<Omit<CredentialsUpdate, "assign">>
	) {
		const newCredentials = await api.post<CredentialsUpdate>("secretary/password-update", {
			newPassword: secretaryCredentials?.newPassword,
			newPasswordConfirmation: secretaryCredentials?.newPasswordConfirmation,
			currentPassword: secretaryCredentials?.currentPassword,
			...patch,
		});
		this.secretaryCredentials.update(secretaryMap => (secretaryMap ? newCredentials.data : null));
	}

	async editPatient(
		patient: Patient,
		practitionerId: string,
		patch: Partial<Omit<PatientCreationDTO, "assign">>,
		addTutorAfter: number | null
	) {
		const newPatient = await api.post<Patient>(`/secretary/practitioner/${practitionerId}/patient/${patient.id}`, {
			firstName: patient.firstName,
			lastName: patient.lastName,
			birthDate: patient.birthDate,
			email: patient.email,
			phoneNumber1: patient.phoneNumber1,
			phoneNumber2: patient.phoneNumber2,
			gender: patient.gender,
			address: patient.address,
			legalGuardian1Id: patient.legalGuardian1?.id,
			legalGuardian2Id: patient.legalGuardian2?.id,
			addTutorAfter,
			...patch,
		});
		// This is a bit tricky but if we omit one these field, the api understands it as null and clean its value
		this.patientByIds.update(patientMap => patientMap.set(newPatient.data.id, newPatient.data));
	}

	async deleteSurgery(surgeryId: string, practitionerId: string) {
		const newSurgery = await api.delete<Surgery>(`/secretary/practitioner/${practitionerId}/surgery/${surgeryId}`);
		this.surgeryByIds.update(surgeryMap => surgeryMap.set(newSurgery.data.id, newSurgery.data));
		return newSurgery.data;
	}

	async fetchPostOperativeBySurgery(practitionerId: string, surgeryId: string): Promise<PostOperative[]> {
		const postOperatives = await api.get<PostOperative[]>(
			`/secretary/practitioner/${practitionerId}/surgery/${surgeryId}/postOperatives`
		);
		this.postOperativesBySurgeryId.update(postOperativesMap => postOperativesMap.set(surgeryId, postOperatives.data));
		return postOperatives.data;
	}

	async createSurgeryPostOperatives(
		practitionerId: string,
		surgeryId: string,
		postOperatives: PostOperativeUpdateDTO[]
	): Promise<PostOperative[]> {
		const arrayPostOperatives = await api.post<PostOperative[]>(
			`/secretary/practitioner/${practitionerId}/surgery/${surgeryId}/postOperatives`,
			postOperatives
		);
		this.postOperativesBySurgeryId.update(arrayPostOperativesMap =>
			arrayPostOperativesMap.set(surgeryId, arrayPostOperatives.data)
		);
		return arrayPostOperatives.data;
	}

	async updateSurgeryPostOperative(
		practitionerId: string,
		surgeryId: string,
		postOperativeId: string,
		update: PostOperativeUpdateDTO
	) {
		const postOperative = await api.post<PostOperative>(
			`/secretary/practitioner/${practitionerId}/surgery/${surgeryId}/postOperative/${postOperativeId}`,
			update
		);
		return postOperative.data;
	}

	async deleteSurgeryPostOperative(practitionerId: string, surgeryId: string, postOperativeId: string) {
		await api.delete(`/secretary/practitioner/${practitionerId}/surgery/${surgeryId}/postOperative/${postOperativeId}`);
	}
	async fetchPractitionerAndHospitalModules(
		practitionerId: string,
		hospitalId: string | null
	): Promise<Module[] | undefined> {
		if (hospitalId) {
			const modules = await api.get<Module[]>(
				`/secretary/practitioner/${practitionerId}/hospital/${hospitalId}/modules`
			);
			this.modulesByPractitionerId.update(modulesMap => modulesMap.set(practitionerId as string, modules.data));
			return modules.data;
		}
	}
	async fetchPractitionerModules(practitionerId: string): Promise<Module[]> {
		const modules = await api.get<Module[]>(`/secretary/practitioner/${practitionerId}/modules`);
		this.modulesByPractitionerId.update(modulesMap => modulesMap.set(practitionerId as string, modules.data));
		return modules.data;
	}
	async fetchHealthcareProvider(practitionerId: string): Promise<HealthcareProviderAgency[]> {
		const healthcareProvider = await api.get<HealthcareProviderAgency[]>(`/secretary/practitioner/${practitionerId}/healthcare-provider`);
		this.healthcareProviderBySecretaryId.update(healthcareProviderMap =>
			healthcareProviderMap.set("secretaryId", healthcareProvider.data)
		);
		return healthcareProvider.data;
	}
	async fetchDocCustom(surgeryId: string, practitionerId: string): Promise<DocCustom[]> {
		const docCustom = await api.get<DocCustom[]>(
			`/secretary/practitioner/${practitionerId}/surgery/${surgeryId}/docCustoms/forms`
		);
		this.docCustoms.update(() => docCustom.data);
		return docCustom.data;
	}
	async updateDocCustom(surgeryId: string, practitionerId: string, update: DocCustomDTO[]): Promise<DocCustom[]> {
		const docCustom = await api.post<DocCustom[]>(
			`/secretary/practitioner/${practitionerId}/surgery/${surgeryId}/createCustomDocument`,
			update
		);
		return docCustom.data;
	}
	async updateDocCustomValue(surgeryId: string, valueInput: string) {
		await api.post(`/secretary/surgery/${surgeryId}/createCustomDocument/value`, { valueInput });
	}
	async updateDocCustomValues(surgeryId: string, valueInput: CustomDpm) {
		await api.post(`/secretary/surgery/${surgeryId}/createCustomDpmDocument/values`, valueInput);
	}
	async updateCustomDpmValues(surgeryId: string, customDpmValues: CustomDpm) {
		await api.post(`/secretary/surgery/${surgeryId}/updateCustomDpmValues`, customDpmValues);
	}
	async fetchCCAM(ccam: string, practitionerId: string): Promise<CCAM> {
		const ccamData = await api.get<CCAM>(`/secretary/practitioner/${practitionerId}/ccam/${ccam}`);
		this.ccam.update(() => ccamData.data);
		return ccamData.data;
	}
	async fetchCCAMS(practitionerId: string): Promise<CCAM[]> {
		const ccamData = await api.get<CCAM[]>(`/secretary/practitioner/${practitionerId}/ccam`);
		this.ccams.update(() => ccamData.data);
		return ccamData.data;
	}
	async fetchCCAMByDescription(description: string, practitionerId: string): Promise<CCAM[] | undefined> {
		if (description.length > 3) {
			const ccamData = await api.get<CCAM[]>(
				`/secretary/practitioner/${practitionerId}/ccam/description/${description}`
			);
			this.ccams.update(() => ccamData.data);
			return ccamData.data;
		}
	}
	async getCCAMByCode(ccam: string, practitionerId: string): Promise<CCAM | undefined> {
		if (ccam.length > 6) {
			const ccamData = await api.get<CCAM>(
				`/secretary/practitioner/${practitionerId}/ccam/${ccam}`
			);
			return ccamData.data;
		}
	}

	async getCCAMById(ccamId: string, practitionerId: string): Promise<CCAM | undefined> {
		if (ccamId.length > 7) {
			const ccamData = await api.get<CCAM>(
				`/secretary/practitioner/${practitionerId}/ccamId/${ccamId}`
			);
			return ccamData.data;
		}
	}

	async fetchCancerTypes(search?: string): Promise<CancerType[]> {
		const paramsObject = {
			search,
		};
		const params = buildSearchParams(paramsObject);
		const cancerTypeData = await api.get<CancerType[]>(`/secretary/cancerType`, {params});
		this.cancerTypes.update(() => cancerTypeData.data);
		return cancerTypeData.data;
	}
	async assignUserProfile(secretaryId: string, userProfile: UserProfile | null) {
		const result = await api.post("practitioner/secretary/userprofile", userProfile);
		this.secretary.set(result.data);
		return result.data;
	}
	async createFormValuesBySurgery(practitionerId: string, surgeryId: string) {
		const docCustom = await api.post(`/secretary/practitioner/${practitionerId}/surgery/${surgeryId}/createFormValues`);
		return docCustom.data;
	}
	async fetchSurgeriesWithHpus(
		pageOptions: PaginationOptions,
		search?: string,
		practitionerId?: string,
		hpState? : string
	): Promise<void> {
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
		};
		const params = buildSearchParams(paramsObject);
		let surgeriesWithHpus: AxiosResponse | null = null;
		if (hpState == "ARCHIVED") {
			surgeriesWithHpus = await api.get<Paginated<SurgeryWithItsHpu>>(
				`secretary/practitioner/${practitionerId}/surgeriesWithHpus/archived`,
				{
					params,
				}
			);
		} else {
			surgeriesWithHpus = await api.get<Paginated<SurgeryWithItsHpu>>(
				`secretary/practitioner/${practitionerId}/surgeriesWithHpus`,
				{
					params,
				}
			);
		}

		practitionerService.surgeriesWithHpus.update(surgeriesPages =>
			surgeriesPages.set(surgeriesWithHpus?.data.page, surgeriesWithHpus?.data)
		);
	}
	async countSurgeriesWithHpusAction(practitionerId: string) {
		const count = await api.get(`/secretary/practitioner/${practitionerId}/surgeriesWithHpus/count`);
		practitionerService.countSurgeriesWithHpus.update(() => count.data);
		return count.data;
	}

	async saveSign(practitionerId: string): Promise<Practitioner> {
		const practitionerUpdated = await api.post<Practitioner>(`/secretary/practitioner/${practitionerId}/saveSign`);
		return practitionerUpdated.data;
	}

	async storeSign(file: Blob | undefined, nameFile: string | undefined, practitionerId: string) {
		const formData = new FormData();
		if (file != undefined) {
			formData.append("input", file, nameFile);
		}

		await api.post(`/secretary/practitioner/${practitionerId}/store/sign`, formData, {
			headers: {
				Accept: "*/*",
				"Content-Type": "multipart/form-data",
			},
		});
	}

	async signDocument(surgeryId: string) {
		await api.post(`/secretary/surgery/${surgeryId}/sign-documents`);
	}

	async rejectDocument(surgeryDocumentId: string, rejectDescription: string, practitionerId: string) {
		await api.post(`/secretary/practitioner/${practitionerId}/surgeryDocument/${surgeryDocumentId}/reject-document`, {
			rejectDescription: rejectDescription,
		});
	}

	async sendNotificationHpuRejectedDocs(surgeryId: string, documents: RejectedDocument[]) {
		await api.post<Document[]>(`/secretary/surgery/${surgeryId}/sendNotifPsadRejected`, documents);
	}

	async clickSuivi(practitionerId: string) {
		await api.get(`/secretary/suivi/practitioner/${practitionerId}`);
	}

	async fetchParad(patientId: string, surgeryId: string) {
		try {
			const result = await api.get<FormParad>(`/secretary/patient/${patientId}/surgery/${surgeryId}/parad`);
			this.formParad.set(result.data);
			return result.data;
		} catch (e) {
			console.log(e)
			console.log("Query fetchPatient aborted");
		}
		return null;
	}

	async createParad(answers: FormParad, patient: Patient, surgery: Surgery | SurgeryHPU) {
		await api.post<FormParad>(`/secretary/${patient.id}/surgery/${surgery.id}/parad/new`, answers);
	}

	async deleteParad(formParad: FormParad) {
		if (formParad.id != undefined) {
			await api.delete(`/secretary/parad/${formParad.id}/delete`);
		}
	}

	async fetchPractitionerAmount(practitionerId: string) {
		try {
			const result = await api.get<PractitionerAmount[]>(`/secretary/practitioner/${practitionerId}/practitioner-amount`);
			this.practitionerAmounts.set(result.data);
			return result.data;
		} catch (e) {
			console.log(e)
			console.log("Query fetchPractitionerAmount aborted");
		}
		return null;
	}

	async addPractitionerAmount(practitionerId: string, practitionerAmount: PractitionerAmount) {
		await api.post<PractitionerAmount>(`/secretary/practitioner/${practitionerId}/practitioner-amount/add`, practitionerAmount);
	}

	async updatePractitionerAmount(practitionerId: string, practitionerAmountId: string, practitionerAmount: PractitionerAmount) {
			await api.post<PractitionerAmount>(`/secretary/practitioner/${practitionerId}/practitioner-amount/${practitionerAmountId}/update`, practitionerAmount);
	}

	async deletePractitionerAmount(practitionerId: string, practitionerAmountId: string) {
		await api.delete<PractitionerAmount>(`/secretary/practitioner/${practitionerId}/practitioner-amount/${practitionerAmountId}/delete`);
	}

	async updateOptam(practitionerId: string, InputOptam: InputOptam) {
		await api.post(`/secretary/practitioner/${practitionerId}/optam/update`, InputOptam);
	}

	async fetchPractitionerContact(practitionerId: string) {
		try {
			const result = await api.get<PractitionerContact[]>(`/secretary/practitioner/${practitionerId}/contacts`);
			this.practitionerContacts.set(result.data);
			return result.data;
		} catch (e) {
			console.log(e)
			console.log("Query fetchPractitionerContact aborted");
		}
		return null;
	}

	async addPractitionerContact(practitionerId: string, practitionerContact: PractitionerContact) {
		await api.post<PractitionerContact>(`/secretary/practitioner/${practitionerId}/contact/new`, practitionerContact);
	}

	async updatePractitionerContact(practitionerId: string, practitionerContactId: string, practitionerContact: PractitionerContact) {
		await api.post<PractitionerContact>(`/secretary/practitioner/${practitionerId}/contact/${practitionerContactId}`, practitionerContact);
	}
	async deletePractitionerContact(practitionerId: string, practitionerContactId: string) {
		await api.delete<PractitionerContact>(`/secretary/practitioner/${practitionerId}/contact/${practitionerContactId}/delete`);
	}
	async fetchPractitionerDocCustom(practitionerId: string, pageOptions: PaginationOptions, search?: string,) {
		try {
			const paramsObject = {
				...buildPaginationParams(pageOptions),
				search,
			};
			const params = buildSearchParams(paramsObject);

			const result = await api.get<Paginated<DocCustom>>(`/secretary/practitioner/${practitionerId}/docCustomStatus`, {params});
			this.docCustomsPagined.update(surgeryPages => surgeryPages.set(result.data.page, result.data));
		} catch (e) {
			console.log(e)
			console.log("Query fetchPractitionerDocCustom aborted");
		}
		return null;
	}

	async postDocCustom(formData: FormData, practitionerId: string, task: string): Promise<AxiosResponse> {
		return await api.post(`/secretary/practitioner/${practitionerId}/addDocCustom/${task}`, formData)
	}

	async deleteDocCustom(docCustomId: string, practitionerId: string): Promise<void> {
		await api.delete(`secretary/practitioner/${practitionerId}/docCustom/${docCustomId}/delete`);
	}

	async getDocCustom(practitionerId: string, documentId: string | undefined, name: string): Promise<string | Blob | undefined> {
		if (documentId) {
			try {
				const response = await api.get(`secretary/practitioner/${practitionerId}/document/${documentId}/download`, {
					responseType: 'blob', // Important: Indique que la réponse doit être traitée comme un Blob
				});

				return downloadBlob(response)
			} catch (error) {
				console.error("Erreur lors du téléchargement du document:", error);
			}
		}
	}

	async addCustomCcam(formData: FormData, practitionerId: string): Promise<AxiosResponse> {
		return await api.post(`/secretary/practitioner/${practitionerId}/addCustomCcam`, formData)
	}
}

export const secretaryService = new SecretaryService();

export function usePatient(
	patientId: string,
	practitionerId: string
): { loading: boolean; patient: Patient | undefined } {
	const user = useUser();
	const patient = useComputedObservable(() => secretaryService.patientByIds.get().get(patientId), [user, patientId]);
	const [loading, setLoading] = useState(true);
	const fetchPatient = useCallback(async () => {
		try {
			if (user && patientId) {
				setLoading(true);
				await secretaryService.fetchPatient(patientId, practitionerId as string);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [user, patientId, practitionerId]);

	useEffect(() => {
		fetchPatient();
	}, [fetchPatient]);

	return {
		loading,
		patient,
	};
}

export function useSecretaryProfile() {
	return useObservable(secretaryService.profile);
}

export function useSecretaryPractitioners() {
	return useObservable(secretaryService.practitioners);
}

export function usePractitioner(practitionerId: string | undefined): { practitioner: Practitioner | null } {
	const user = useUser();
	const practitioner = useComputedObservable(() => secretaryService.practitioner.get(), []);
	const fetchPractitioner = useCallback(async () => {
		try {
			if (user && practitionerId as string) {
				await secretaryService.fetchPractitioner(practitionerId as string);
			}
		} catch (e) {
			console.log(e);
		}
	}, [user, practitionerId]);
	useEffect(() => {
		fetchPractitioner().then();
	}, [fetchPractitioner]);
	return {
		practitioner,
	};
}

export function isSurgeryPast(surgery: Surgery) {
	const actualDate = new Date();
	const surgeryDate = new Date(surgery.surgeryDate);
	return !(isDateSameDay(surgeryDate, actualDate) || surgeryDate > actualDate);
}

export function usePatientSurgeriesSecretary(
	patientId: string,
	practitionerId: string
): {
	loading: boolean;
	surgeries: Surgery[] | undefined;
	pastSurgeries: Surgery[];
	futureSurgeries: Surgery[];
} {
	const user = useUser();
	const surgeries = useComputedObservable(() => secretaryService.surgeriesByPatientId.get().get(patientId), [
		user,
		patientId,
	]);
	const [loading, setLoading] = useState(true);
	const [pastSurgeries, futureSurgeries] = useMemo(() => {
		const actualDate = new Date();
		return (
			surgeries?.reduce(
				(acc: [Surgery[], Surgery[]], surgery) => {
					const surgeryDate = new Date(surgery.surgeryDate);
					return isDateSameDay(surgeryDate, actualDate) || surgeryDate > actualDate
						? [acc[0], [...acc[1], surgery]]
						: [[...acc[0], surgery], acc[1]];
				},
				[[], []]
			) ?? [[], []]
		);
	}, [surgeries]);

	const fetchPatientSurgeries = useCallback(async () => {
		try {
			if (user && patientId) {
				setLoading(true);
				await secretaryService.fetchPatientSurgeries(patientId, practitionerId as string);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [user, patientId, practitionerId]);

	useEffect(() => {
		fetchPatientSurgeries();
	}, [fetchPatientSurgeries]);

	return {
		loading,
		surgeries,
		pastSurgeries,
		futureSurgeries,
	};
}
export function usePatients(
	pageOptions: PaginationOptions,
	sortOption: SortOption | null,
	search?: string
): { loading: boolean; patients: Paginated<Patient> | undefined; refresh: () => Promise<void> } {
	const user = useUser();
	const { practitionerId } = useParams<{ practitionerId: string }>();
	const patients = useComputedObservable(() => secretaryService.patients.get().get(pageOptions.page), [
		user,
		pageOptions,
		sortOption,
	]);
	const [loading, setLoading] = useState(true);
	const fetchPatients = useCallback(async () => {
		try {
			if (user && practitionerId as string) {
				setLoading(true);
				await secretaryService.fetchPatients(
					pageOptions,
					practitionerId as string,
					sortOption ? [sortOption] : undefined,
					search
				);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [pageOptions, sortOption, user, search, practitionerId]);

	useEffect(() => {
		fetchPatients();
	}, [fetchPatients]);

	return {
		loading,
		patients,
		refresh: fetchPatients,
	};
}

export function useSurgery(
	practitionerId: string,
	surgeryId?: string
): { loading: boolean; surgery: Surgery | undefined } {
	const user = useUser();
	const surgery = useComputedObservable(
		() => (surgeryId ? secretaryService.surgeryByIds.get().get(surgeryId) : undefined),
		[user, surgeryId]
	);

	const [loading, setLoading] = useState(true);
	const fetchSurgeries = useCallback(async () => {
		try {
			if (user && surgeryId) {
				setLoading(true);
				await secretaryService.fetchSurgery(surgeryId, practitionerId as string);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [user, surgeryId, practitionerId]);

	useEffect(() => {
		fetchSurgeries();
	}, [fetchSurgeries]);

	return {
		loading,
		surgery,
	};
}

export function useSurgeries(
	pageOptions: PaginationOptions,
	sortOption: SortOption | null,
	search?: string,
	active?: boolean,
	future?: boolean
): { loading: boolean; surgeries: Paginated<Surgery> | undefined; refresh: () => Promise<void> } {
	const user = useUser();
	const { practitionerId } = useParams<{ practitionerId: string }>();
	const surgeries = useComputedObservable(() => secretaryService.surgeries.get().get(pageOptions.page), [
		user,
		pageOptions,
		sortOption,
	]);
	const [loading, setLoading] = useState(true);
	const fetchSurgeries = useCallback(async () => {
		try {
			if (user && practitionerId as string) {
				setLoading(true);
				const implicitSortOption: SortOption = { by: "surgeryDate", order: "ASC" };
				await secretaryService.fetchSurgeries(
					pageOptions,
					practitionerId as string,
					sortOption ? [sortOption, implicitSortOption] : [implicitSortOption],
					search,
					active,
					future
				);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [user, pageOptions, sortOption, search, active, future, practitionerId]);

	useEffect(() => {
		fetchSurgeries();
	}, [fetchSurgeries]);

	return {
		loading,
		surgeries,
		refresh: fetchSurgeries,
	};
}

export function usePostOperativesSecretary(surgeryId: string) {
	const user = useUser();
	const { practitionerId } = useParams<{ practitionerId: string }>();
	const postOperatives = useComputedObservable(() => secretaryService.postOperativesBySurgeryId.get().get(surgeryId), [
		user,
		surgeryId,
	]);
	const fetchPostOperatives = useCallback(async () => {
		try {
			if (user && surgeryId && practitionerId as string) {
				await secretaryService.fetchPostOperativeBySurgery(practitionerId as string, surgeryId);
			}
		} catch (e) {
			console.log(e);
		}
	}, [user, surgeryId]);
	useEffect(() => {
		fetchPostOperatives().then();
	}, [fetchPostOperatives]);

	return postOperatives;
}

export function useModulesByPractitionerId(practitionerId: string | undefined) {
	const user = useUser();
	const modules = useComputedObservable(
		() => secretaryService.modulesByPractitionerId.get().get(practitionerId ?? ""),
		[practitionerId]
	);
	const fetchModules = useCallback(async () => {
		try {
			if (user && practitionerId as string) {
				await secretaryService.fetchPractitionerModules(practitionerId as string);
			}
		} catch (e) {
			console.log(e);
		}
	}, [user, practitionerId]);
	useEffect(() => {
		fetchModules().then();
	}, [fetchModules, practitionerId]);

	return modules;
}

export function useHealthCareProviders(practitionerId: string) : {healthcareProvidersAgencies: HealthcareProviderAgency[] | undefined} {
	const user = useUser();
	const healthcareProvidersAgencies = useComputedObservable(() =>
		secretaryService.healthcareProviderBySecretaryId.get().get("secretaryId")
	);
	const fetchHealthcareProvider = useCallback(async () => {
		try {
			if (user) {
				await secretaryService.fetchHealthcareProvider(practitionerId as string);
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);
	useEffect(() => {
		fetchHealthcareProvider().then();
	}, [fetchHealthcareProvider]);
	return { healthcareProvidersAgencies };
}

export function useDocs(surgeryId: string, practitionerId: string): { docCustomForms: DocCustom[] } {
	const user = useUser();
	const docCustomForms = useComputedObservable(() => secretaryService.docCustoms.get(), []);
	const fetchDocs = useCallback(async () => {
		try {
			if (user) {
				await secretaryService.fetchDocCustom(surgeryId, practitionerId as string);
			}
		} catch (e) {
			console.log("Query fetch DocCustomForm aborted");
		}
	}, [user]);
	useEffect(() => {
		fetchDocs().then();
	}, [fetchDocs]);
	return { docCustomForms };
}

export function useCCAMDatabase(practitionerId: string | undefined): { ccamList: CCAM[] } {
	const user = useUser();
	const ccamList = useComputedObservable(() => secretaryService.ccams.get(), []);
	const fetchCCAMS = useCallback(async () => {
		try {
			if (user && practitionerId as string) {
				await secretaryService.fetchCCAMS(practitionerId as string);
			}
		} catch (e) {
			console.log("Query fetch DocCustomForm aborted");
		}
	}, []);
	useEffect(() => {
		fetchCCAMS().then();
	}, []);
	return { ccamList };
}
export function useCancerType(search?: string): { cancerTypeList: CancerType[] } {
	const user = useUser();
	const cancerTypeList = useComputedObservable(() => secretaryService.cancerTypes.get(), []);
	const fetchCancerTypes = useCallback(async () => {
		try {
			if (user) {
				await secretaryService.fetchCancerTypes(search);
			}
		} catch (e) {
			console.log("Query fetch CancerType aborted");
		}
	}, []);
	useEffect(() => {
		fetchCancerTypes().then();
	}, []);
	return { cancerTypeList };
}

export function useSecretaryFormParad(patientId: string, surgeryId: string, audience: string): { secretaryFormParad: FormParad | null } {
	const user = useUser();
	const secretaryFormParad = useComputedObservable(() => secretaryService.formParad.get(), [])
	const fetchFormParad = useCallback(async () => {
		try {
			if (user && audience == "secretary") {
				await secretaryService.fetchParad(patientId, surgeryId);
			}
		} catch (e) {
			console.log(e);
		}
	}, [user, patientId, surgeryId, audience]);
	useEffect(() => {
		fetchFormParad().then();
	}, [fetchFormParad]);
	return {secretaryFormParad};
}

export function useSecretaryPractitionerAmount(): PractitionerAmount[] {
	return useObservable(secretaryService.practitionerAmounts);
}
export function useSecretaryPractitionerContact(): PractitionerContact[] {
	return useObservable(secretaryService.practitionerContacts);
}
export function splitAddress(address: string): string[]{
	return address.split(",")
}
export function useSecretaryPractitionerDocCustom(
	pageOptions: PaginationOptions,
	search?: string,
): { loading: boolean; practitionerDocCustoms: Paginated<DocCustom> | undefined; refresh: () => Promise<void> } {
	const user = useUser();
	const { practitionerId } = useParams<{ practitionerId: string }>();
	const practitionerDocCustoms = useComputedObservable(() => secretaryService.docCustomsPagined.get().get(pageOptions.page), [
		user,
		pageOptions,
	]);
	const [loading, setLoading] = useState(true);
	const fetchDocCustom = useCallback(async () => {
		try {
			if (user && practitionerId as string) {
				setLoading(true);
				const implicitSortOption: SortOption = { by: "whenCreated", order: "ASC" };
				await secretaryService.fetchPractitionerDocCustom(
					practitionerId as string,
					pageOptions,
					search,
				);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [user, pageOptions, search, practitionerId]);

	useEffect(() => {
		fetchDocCustom();
	}, [fetchDocCustom]);

	return {
		loading,
		practitionerDocCustoms,
		refresh: fetchDocCustom,
	};
}

export function downloadBlob(response: AxiosResponse<any, any>, zip = false): string | Blob {
	if (!zip) {
		// Créer un Blob à partir de la réponse
		const blob = new Blob([response.data], { type: 'application/pdf' }); // Spécifiez le type MIME correct si nécessaire

		// Créer une URL pour le Blob
		const url = window.URL.createObjectURL(blob);
		window.open(url, '_blank');

		const fileName = 'SOPHI_doc.pdf'; // Nom par défaut au cas où le header serait absent
		// Créer un élément <a> dynamique pour le téléchargement
		const a = document.createElement('a');
		a.href = url;
		a.download = fileName;  // Nom du fichier pour le téléchargement
		document.body.appendChild(a);  // Ajouter au DOM temporairement

		// Déclencher le clic pour télécharger le fichier
		a.click();

		// Nettoyage : Supprimer l'élément <a> et libérer l'URL temporaire
		document.body.removeChild(a);
		// Nettoyer
		window.URL.revokeObjectURL(url);

		return url
	}
	else {
		return new Blob([response.data], { type: 'application/pdf' }); // Spécifiez le type MIME correct si nécessaire
	}
}
