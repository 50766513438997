import styled from "@emotion/styled";
import { Button } from "components/button";
import { Greetings } from "components/greetings";
import { SurgeryStepIcon } from "components/surgery-step-icon";
import { AnesthesiaType } from "domain/anesthesia";
import {
	Document,
	DocumentType,
	someDocumentsRequireSignature,
	SurgeryDocumentAdmission,
	SurgeryDocumentSource,
} from "domain/document";
import { formParadOk, Patient, patientHasFilledScoringForm, patientHasFilledTheirInfo } from "domain/patient";
import { Module, splitSurgeryDocuments, Surgery } from "domain/surgery";
import { SurgeryStep } from "domain/surgery-step";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useDocumentTypes } from "services/document-type-service";
import { PatientService, patientService, useSurgeries, useSurgery } from "services/patient-service";
import { colors, desktop, mobile, row, sizes, Spacer, stack } from "style";
import { useI18n } from "utils/i18n";
import { SurgeryDatesSelector } from "./surgery-dates-selector";
import { hospitalService, useModules } from "../../services/hospital-service";
import { createModal } from "../../services/modal-service";
import { MySignModal } from "../modals/my-sign-modal";
import { AxiosError } from "axios";
import { ApiError } from "../../services/api";
import ical, { ICalCalendarMethod } from "ical-generator";
import { useCCAMDatabase } from "../../utils/use-CCAM-database";
import ArrowIcon from "@assets/icons/arrow-external.svg";
import { css, keyframes } from "@emotion/react";

interface PatientHeaderProps {
	patient?: Patient;
	currentStep: SurgeryStep;
	surgeryId: string;
	update?: boolean;
}

// Gris (couleur vert ou gris sans le check)
function isStepAvailable(
	patient: Patient,
	surgery: Surgery,
	step: SurgeryStep,
	documentTypes: DocumentType[],
	modules: Module[]
) {
	const allDocsFilled = splitSurgeryDocuments(surgery, patient, documentTypes).missingDocuments.length === 0;
	const allDocsSigned = !someDocumentsRequireSignature(surgery.surgeryDocuments);

	const anesthesiaFilled =
		surgery.anesthesiaType == AnesthesiaType.Local ||
		(surgery.anesthesiaType == AnesthesiaType.General && surgery.anesthesiaAppointmentDate);
	const trustedPersonCompleted = surgery.surgeryDocuments.reduce(
		(b, doc) =>
			b ||
			(doc.status == SurgeryDocumentAdmission.Completed &&
				(doc.documentType.label == "Médico-légal : 0 Personne de confiance" ||
					doc.documentType.label == "Médico-légal : Personne de confiance")) ||
			(doc.status == SurgeryDocumentAdmission.Pending &&
				doc.documentType.label == "Médico-légal : Personne de confiance"),
		false
	);
	const admissionFormCompleted = surgery.surgeryDocuments.reduce(
		(b, doc) =>
			b || (doc.status == SurgeryDocumentAdmission.Completed && doc.documentType.label == "Préop : Fiche d'admission"),
		false
	);
	switch (step) {
		case SurgeryStep.PatientInfo:
			return true;
		case SurgeryStep.PatientDocuments:
			return patientHasFilledTheirInfo(patient, surgery) && (surgery.practitioner?.evaluation ? formParadOk(surgery) : patientHasFilledScoringForm(surgery))
		case SurgeryStep.Anesthesia:
			return (
				patientHasFilledTheirInfo(patient, surgery) &&
				patientHasFilledScoringForm(surgery) &&
				formParadOk(surgery) &&
				((allDocsFilled && (surgery.healthcareProvider || modules.some((mod) => mod.type == "pre-admission"))) || (!allDocsFilled && surgery.healthcareProvider == null)) &&
				(surgery.surgeryDocuments.find(doc => doc.status != null) ? admissionFormCompleted : true)
			);
		case SurgeryStep.ForensicDocuments:
			if (modules.some(item => "anesthesia" == item.type)) {
				return (
					patientHasFilledTheirInfo(patient, surgery) && (surgery.practitioner?.evaluation ? formParadOk(surgery) : patientHasFilledScoringForm(surgery)) &&
					anesthesiaFilled &&
					allDocsFilled &&
					(surgery.createdByJob &&
					surgery.surgeryDocuments.find(doc => doc.status != null && doc.documentType.source != "HPU")
						? admissionFormCompleted
						: true)
				);
			} else if (surgery.healthcareProvider || modules.some((mod) => mod.type == "pre-admission")) {
				return (
					patientHasFilledTheirInfo(patient, surgery) && (surgery.practitioner?.evaluation ? formParadOk(surgery) : patientHasFilledScoringForm(surgery)) &&
					allDocsFilled &&
					(surgery.createdByJob // pour CARC
						? surgery.surgeryDocuments.find(doc => doc.status != null && doc.documentType.source != "HPU")
							? admissionFormCompleted
							: true
						: true)
				);
			} else {
			return (
				patientHasFilledTheirInfo(patient, surgery) && (surgery.practitioner?.evaluation ? formParadOk(surgery) : patientHasFilledScoringForm(surgery)) &&
				(surgery.healthcareProvider &&
				surgery.createdByJob && // pour CARC
				surgery.surgeryDocuments.find(doc => doc.status != null && doc.documentType.source != "HPU")
					? admissionFormCompleted
					: true)
			);
		}
			return patientHasFilledTheirInfo(patient, surgery) && (surgery.practitioner?.evaluation ? formParadOk(surgery) : patientHasFilledScoringForm(surgery))
		case SurgeryStep.Surgery:
			//1er cas : On a les 2 modules
			if (modules.some(item => "anesthesia" == item.type)) {
				if (
					patientHasFilledTheirInfo(patient, surgery) &&
					patientHasFilledScoringForm(surgery) &&
					formParadOk(surgery) &&
					allDocsFilled &&
					(surgery.anesthesiaType == null ||
						surgery.anesthesiaType !== AnesthesiaType.General ||
						(!!surgery.anesthesiaAppointmentDate && surgery.anesthesiaAppointmentHonored !== false)) &&
					(surgery.surgeryDocuments.find(doc => doc.status != null) ? trustedPersonCompleted : true) &&
					(surgery.surgeryDocuments.find(doc => doc.status != null) ? admissionFormCompleted : true) &&
					allDocsSigned
				) {
					return true;
				}
			}
			//3eme cas : On a uniquement le module anesthesia
			else if (modules.some(item => "anesthesia" == item.type)) {
				if (
					patientHasFilledTheirInfo(patient, surgery) && (surgery.practitioner?.evaluation ? formParadOk(surgery) : patientHasFilledScoringForm(surgery)) &&
					allDocsSigned &&
					(surgery.anesthesiaType == null ||
						surgery.anesthesiaType !== AnesthesiaType.General ||
						(!!surgery.anesthesiaAppointmentDate && surgery.anesthesiaAppointmentHonored !== false)) &&
					(surgery.surgeryDocuments.find(doc => doc.status != null) ? trustedPersonCompleted : true) &&
					(surgery.surgeryDocuments.find(doc => doc.status != null) ? admissionFormCompleted : true)
				) {
					return true;
				}
			}

			//2eme cas : On a uniquement le module pre-admission
			else if (surgery.healthcareProvider || modules.some((mod) =>  mod.type == "pre-admission")) {
				if (
					patientHasFilledTheirInfo(patient, surgery) && (surgery.practitioner?.evaluation ? formParadOk(surgery) : patientHasFilledScoringForm(surgery)) &&
					allDocsFilled &&
					allDocsSigned &&
					(surgery.surgeryDocuments.find(doc => doc.status != null) ? trustedPersonCompleted : true) &&
					(surgery.surgeryDocuments.find(doc => doc.status != null) ? admissionFormCompleted : true)
				) {
					return true;
				}
			}
			//4eme cas : On a aucun module
			else if (
				patientHasFilledTheirInfo(patient, surgery) && (surgery.practitioner?.evaluation ? formParadOk(surgery) : patientHasFilledScoringForm(surgery)) &&
				allDocsSigned &&
				(surgery.surgeryDocuments.find(doc => doc.status != null) ? admissionFormCompleted : true) &&
				(surgery.surgeryDocuments.find(doc => doc.status != null) ? trustedPersonCompleted : true)
			) {
				return true;
			}
			return false;
	}
}

// Orange
function isStepMissingSomething(patient: Patient, surgery: Surgery, step: SurgeryStep, documentTypes: DocumentType[]) {
	const docs = splitSurgeryDocuments(surgery, patient, documentTypes);
	const allDocsFilled = docs.missingDocuments.length === 0 && docs.rejectedDocuments.length === 0;
	const allDocsSigned = !someDocumentsRequireSignature(surgery.surgeryDocuments);
	const trustedPersonCompleted = surgery.surgeryDocuments.reduce(
		(b, doc) =>
			b ||
			(doc.status == SurgeryDocumentAdmission.Completed &&
				(doc.documentType.label == "Médico-légal : 0 Personne de confiance" ||
					doc.documentType.label == "Médico-légal : Personne de confiance")) ||
			(doc.status == SurgeryDocumentAdmission.Pending &&
				doc.documentType.label == "Médico-légal : Personne de confiance"),
		false
	);
	const admissionFormCompleted = surgery.surgeryDocuments.reduce(
		(b, doc) =>
			b || (doc.status == SurgeryDocumentAdmission.Completed && doc.documentType.label == "Préop : Fiche d'admission"),
		false
	);

	switch (step) {
		case SurgeryStep.PatientInfo:
			return !patientHasFilledTheirInfo(patient, surgery) || (surgery.practitioner?.evaluation ? !formParadOk(surgery) : !patientHasFilledScoringForm(surgery))
		case SurgeryStep.PatientDocuments:
			return (
				!patientHasFilledTheirInfo(patient, surgery) || (surgery.practitioner?.evaluation ? !formParadOk(surgery) : !patientHasFilledScoringForm(surgery)) ||
				!allDocsFilled ||
				(surgery.surgeryDocuments.find(doc => doc.status != null) && surgery.createdByJob
					? !admissionFormCompleted
					: false)
			);
		case SurgeryStep.Anesthesia:
			return (
				(patientHasFilledTheirInfo(patient, surgery) || patientHasFilledScoringForm(surgery) || allDocsFilled || formParadOk(surgery)) &&
				surgery.anesthesiaType == AnesthesiaType.General &&
				!surgery.anesthesiaAppointmentDate
			);
		case SurgeryStep.ForensicDocuments:
			return surgery?.surgeryDocuments.filter((doc) => doc.documentType.source != SurgeryDocumentSource.Practitioner).length == 0 &&
			surgery.createdByJob &&
				surgery.surgeryDocuments.find(doc => doc.status != null && doc.documentType.source != "HPU")
				? !trustedPersonCompleted || !allDocsSigned
				: !allDocsSigned;
		case SurgeryStep.Surgery:
			return false;
		case SurgeryStep.PostOp:
			return false;
	}
}

// Vert complété avec le check
function isStepComplete(
	patient: Patient,
	surgery: Surgery,
	step: SurgeryStep,
	documentTypes: DocumentType[],
	modules: Module[]
) {
	const allDocsFilled = splitSurgeryDocuments(surgery, patient, documentTypes).missingDocuments.length === 0;
	const allDocsSigned = !someDocumentsRequireSignature(surgery.surgeryDocuments);
	const trustedPersonCompleted =
		surgery.createdByJob &&
		surgery.surgeryDocuments.reduce(
			(b, doc) =>
				b ||
				(doc.status == SurgeryDocumentAdmission.Completed &&
					(doc.documentType.label == "Médico-légal : 0 Personne de confiance" ||
						doc.documentType.label == "Médico-légal : Personne de confiance")) ||
				(doc.status == SurgeryDocumentAdmission.Pending &&
					doc.documentType.label == "Médico-légal : Personne de confiance"),
			false
		);
	const admissionFormCompleted = surgery.surgeryDocuments.reduce(
		(b, doc) =>
			b || (doc.status == SurgeryDocumentAdmission.Completed && doc.documentType.label == "Préop : Fiche d'admission"),
		false
	);
	switch (step) {
		case SurgeryStep.PatientInfo:
			return patientHasFilledTheirInfo(patient, surgery) && (surgery.practitioner?.evaluation ? formParadOk(surgery) : patientHasFilledScoringForm(surgery))
		case SurgeryStep.PatientDocuments:
			return (
				patientHasFilledTheirInfo(patient, surgery) && (surgery.practitioner?.evaluation ? formParadOk(surgery) : patientHasFilledScoringForm(surgery)) &&
				allDocsFilled &&
				(surgery.createdByJob ? admissionFormCompleted : true)
			);
		case SurgeryStep.Anesthesia:
			return (
				(surgery.anesthesiaType == AnesthesiaType.General && surgery.anesthesiaAppointmentDate != null) ||
				surgery.anesthesiaType == AnesthesiaType.Local
			);
		case SurgeryStep.ForensicDocuments:
			return surgery.surgeryDocuments.find(doc => doc.status != null)
				? trustedPersonCompleted && allDocsSigned
				: allDocsSigned;
		case SurgeryStep.Surgery:
			return !!surgery.hciPatientId && !!surgery.hciPatientId;
	}
}

function isStepErrored(patient: Patient, surgery: Surgery, step: SurgeryStep, documentTypes: DocumentType[]) {
	const patientDocs = splitSurgeryDocuments(surgery, patient, documentTypes);

	switch (step) {
		case SurgeryStep.PatientInfo:
			return false;
		case SurgeryStep.PatientDocuments:
			return patientDocs.rejectedDocuments.length > 0;
		case SurgeryStep.ForensicDocuments:
			return false;
		case SurgeryStep.Anesthesia:
			return surgery.anesthesiaAppointmentHonored === false;
		case SurgeryStep.Surgery:
			return false;
	}
}

export const PatientHeader: React.FC<PatientHeaderProps> = ({ patient, currentStep, surgeryId, update }) => {
	const history = useNavigate();
	const { format } = useI18n();
	const { documentTypes } = useDocumentTypes();
	const { surgeries } = useSurgeries();
	const ccamDatabase = useCCAMDatabase();
	const {surgery} = useSurgery(surgeryId)
	const surgeryNames = surgery?.classificationCode?.split(",");
	const [showStepsToolTip, setShowStepsTooltip] = useState(false);
	const [stepHover, setStepHover] = useState(SurgeryStep.PatientInfo);
	const nouveauTableau = [SurgeryStep.PatientInfo];
	let patientDocument = false
	const documentsPractitioners =
		surgery?.surgeryDocuments.filter((doc) => doc.documentType.source == SurgeryDocumentSource.Practitioner)

	useEffect(() => {
		patientService.fetchSurgery(surgeryId).then()
	}, [surgeryId]);
	// region modules
	function refreshStepsModules(surgery: Surgery | undefined, documentsPractitioners: Document[] | undefined): void {
		if (surgery) {
			refreshModules(surgery).then();
			let contientAnes = false;
			modules?.forEach(it => {
				if (it.type == SurgeryStep.Anesthesia) {
					contientAnes = true;
				}
				if (it.type == "pre-admission") {
					patientDocument = true
				}
			});
			if (surgery && (surgery.healthcareProvider != null || patientDocument)) {
				nouveauTableau[nouveauTableau.length] = SurgeryStep.PatientDocuments;
			}
			if (contientAnes) {
				nouveauTableau[nouveauTableau.length] = SurgeryStep.Anesthesia;
			}

			if (documentsPractitioners && documentsPractitioners?.length > 0) {
				nouveauTableau[nouveauTableau.length] = SurgeryStep.ForensicDocuments;
			}
			nouveauTableau[nouveauTableau.length] = SurgeryStep.Surgery;
			setArraySteps(nouveauTableau);
			// premier rechargement de la page
			let contient = false;
			modules?.forEach(it => {
				if (it.type == "anesthesia") {
					contient = true;
				}
				if (it.type == "pre-admission") {
					patientDocument = true
				}
			});
			if (
				surgery &&
				!contient &&
				currentStep != "patient-documents" &&
				currentStep != "forensic-documents" &&
				currentStep != "patient-info" &&
				currentStep != "surgery"
			) {
				history(`/patient/surgery/${surgery.id}/patient-info`);
			}
		}
	}

	const modules = useModules(surgery?.hospital?.id);
	const [arraySteps, setArraySteps] = useState<SurgeryStep[]>([SurgeryStep.PatientInfo]);

	useEffect(() => {
		if (surgery && documentsPractitioners) {
			refreshStepsModules(surgery, documentsPractitioners);
		}
	}, [surgery, modules]);

	const refreshModules = useCallback(
		async (surgery: Surgery) => {
			if (surgery.hospitalId) {
				await hospitalService.fetchSurgeryHospitalModulesPatient(surgery.hospitalId);
			}
		},
		[surgery, modules]
	);

	const refresh = useCallback(
		async (surgery: Surgery) => {
			refreshStepsModules(surgery, documentsPractitioners);
		},
		[surgery, modules]
	);
	// endregion

	const previousStep: SurgeryStep | undefined = arraySteps[arraySteps.indexOf(currentStep) - 1];
	const nextStep: SurgeryStep | undefined = arraySteps[arraySteps.indexOf(currentStep) + 1];
	const [showTooltip, setShowTooltip] = useState(false);

	const surgeryNeedSignature =
		surgery?.surgeryDocuments
			.filter((doc) => doc.documentType.source != SurgeryDocumentSource.HPU)
			.reduce((needSignature, document) => needSignature || (document.needSignature == 1  && document.isSigned != 1), false) ?? false;

	let result: any = null;
	const documents = surgery
		? surgery.surgeryDocuments.filter(
				doc =>
					!documentTypes.find(docType => doc.documentType.id === docType.id) &&
					doc.documentType.source != SurgeryDocumentSource.HPU
		  )
		: [];

	function logSign(): void {
		const patientService = new PatientService();
		patientService.logSign(surgeryId);
	}
	const formatDateToICal = (dateString: string): string => {
		const date = new Date(dateString);
		const year = date.getUTCFullYear();
		const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
		const day = ('0' + date.getUTCDate()).slice(-2);
		return `${year}${month}${day}`;
	};
	const exportIcal = useCallback((surgery: Surgery | undefined,surgeryNames: string[] | undefined, ccamDatabase: Record<string, string> | null) => {
		if(surgery && surgeryNames && ccamDatabase) {
			const calendar = ical({name: 'Rendez-vous intervention'});
			calendar.method(ICalCalendarMethod.REQUEST);
			const startTime = new Date(surgery.visitStartDate?? surgery.surgeryDate);
			const endTime = new Date(surgery.visitEndDate?? surgery.surgeryDate);
			endTime.setHours(startTime.getHours()+23);
			calendar.createEvent({
				start: startTime,
				end: endTime,
				summary: `Intervention avec le Dr ${surgery.practitioner?.lastName}`,
				description: `Intervention : ${surgeryNames?.map((surgeryName, index) => ccamDatabase?.[surgeryName])}`,
				location: `${surgery.hospital?.name}`,

			});
			// Créez un objet Blob avec le contenu iCal
			const blob = new Blob([calendar.toString()], { type: 'text/calendar' });

			// Créez un objet URL à partir du Blob
			const url = window.URL.createObjectURL(blob);

			// Créez un élément d'ancrage pour déclencher le téléchargement
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `intervention-${formatDateToICal(surgery.surgeryDate)}.ics`);
			document.body.appendChild(link);

			// Simulez un clic sur l'élément d'ancrage pour déclencher le téléchargement
			link.click();

			// Nettoyez l'URL de l'objet Blob
			window.URL.revokeObjectURL(url);

		}
	}, [])

	const sign = useCallback(
		async (surgery: Surgery) => {
			try {
				result = await createModal<string | undefined>(({ onClose }) => (
					<MySignModal
						surgeryId={surgery.id}
						surgeryDate={surgery.surgeryDate}
						documents={documents.filter(document => document.needSignature && !document.isSigned
						&& document.documentType.filename == "sign")}
						onClose={async () => {
							onClose("closed")
							await patientService.fetchSurgery(surgeryId);
						}}
						surgery={surgery}
					/>
				));
				if (result) {
					await patientService.fetchSurgeries();
					await patientService.fetchSurgery(surgeryId);
				}
			} catch (e: any) {
				const apiError: AxiosError<ApiError> = e;
				if (apiError.response?.data.code === "ERR_NO_PHONENUMBER_OTHER_GUARDIAN") {
					alert(format("signError.phoneNumber"));
				}
				console.log(e.message);
			} finally {
				documents.map(async document => {
					if (!document.isSigned && document.needSignature) {
						await patientService.updateDocument(surgeryId, document.id, { toRead: 0 });
					}
				});
			}
		},
		[format, patient?.id]
	);

	if (!patient || !surgery) {
		return null;
	}

	return (
		<Header>
			<Top>
				<Greetings patient={patient} state={currentStep} />
				<Spacer />
				{matchMedia(mobile).matches ? (
					<Steps>
						{arraySteps.map((step, index) => {
							let keepGoing = true
							if (step == "forensic-documents") {
								if (documentsPractitioners?.length == 0) {
									keepGoing = false
								}
							}

							if (keepGoing) {
								const previousStep = arraySteps[arraySteps.indexOf(step) - 1];
								return (
									<Fragment key={index}>
										{index > 0 && modules ? (
											<Progress
												pending={previousStep && isStepMissingSomething(patient, surgery, previousStep, documentTypes)}
											>
												{isStepAvailable(patient, surgery, step, documentTypes, modules) ? (
													<ProgressFilled />
												) : previousStep && isStepAvailable(patient, surgery, previousStep, documentTypes, modules) ? (
													<ProgressHalfFilled />
												) : null}
											</Progress>
										) : null}
										{currentStep === step && modules ? (
											<SurgeryStepIcon
												step={step}
												active
												pending={isStepMissingSomething(patient, surgery, step, documentTypes)}
												focused
												badge={
													isStepErrored(patient, surgery, step, documentTypes)
														? "error"
														: isStepComplete(patient, surgery, step, documentTypes, modules)
															? "complete"
															: undefined
												}
											/>
										) : surgery && modules && isStepAvailable(patient, surgery, step, documentTypes, modules) ? (
											<Link to={`/patient/surgery/${surgery.id}/${step}`}>
												<SurgeryStepIcon
													step={step}
													active
													pending={isStepMissingSomething(patient, surgery, step, documentTypes)}
													badge={
														isStepErrored(patient, surgery, step, documentTypes)
															? "error"
															: isStepComplete(patient, surgery, step, documentTypes, modules)
																? "complete"
																: undefined
													}
												/>
											</Link>
										) : (
											<SurgeryStepIcon step={step} />
										)}
									</Fragment>
								);
							}
						})}
					</Steps>
				) : null}
				<SurgeryDatesSelector
					surgeries={surgeries ?? []}
					surgeryId={surgeryId}
					onSurgeryChange={async newSurgery => {
						history(`/patient/surgery/${newSurgery.id}/patient-info`);
						refresh(newSurgery);
					}}
					wording={{
						futureLabel: "patient.selectSurgery.future.label",
						futurePlaceholder: "patient.selectSurgery.future.placeholder",
						historyLabel: "patient.selectSurgery.history.label",
						historyPlaceholder: "patient.selectSurgery.history.placeholder",
					}}
				/>
			</Top>
			<Buttons>
				{previousStep && modules && isStepAvailable(patient, surgery, previousStep, documentTypes, modules) ? (
					<Link to={`/patient/surgery/${surgery.id}/${previousStep}`}>
						<Button>{format("patient.header.previous")}</Button>
					</Link>
				) : (
					<Button disabled>{format("patient.header.previous")}</Button>
				)}
				{matchMedia(desktop).matches ? (
					<Steps>
						{arraySteps.map((step, index) => {
							let keepGoing = true
							if (step == "forensic-documents") {
								if (documentsPractitioners?.length == 0) {
									keepGoing = false
								}
							}

							if (keepGoing) {
								const previousStep = arraySteps[arraySteps.indexOf(step) - 1];
								return (
									<Fragment key={index}>

										{index > 0 && modules ? (
											<Progress
												pending={previousStep && isStepMissingSomething(patient, surgery, previousStep, documentTypes)}
											>
												{isStepAvailable(patient, surgery, step, documentTypes, modules) ? (
													<ProgressFilled />
												) : previousStep && isStepAvailable(patient, surgery, previousStep, documentTypes, modules) ? (
													<ProgressHalfFilled />
												) : null}
											</Progress>
										) : null}
										{currentStep === step && modules ? (
											<Link
												to={`/patient/surgery/${surgery.id}/${step}`}
												onMouseOver={() => {
													setShowStepsTooltip(true);
													setStepHover(step);
												}}
												onMouseOut={() => setShowStepsTooltip(false)}
											>
												<SurgeryStepIcon
													step={step}
													active
													pending={isStepMissingSomething(patient, surgery, step, documentTypes)}
													focused
													badge={
														isStepErrored(patient, surgery, step, documentTypes)
															? "error"
															: isStepComplete(patient, surgery, step, documentTypes, modules)
																? "complete"
																: undefined
													}
												/>
											</Link>
										) : surgery && modules && isStepAvailable(patient, surgery, step, documentTypes, modules) ? (
											<Link
												to={`/patient/surgery/${surgery.id}/${step}`}
												onMouseOver={() => {
													setShowStepsTooltip(true);
													setStepHover(step);
												}}
												onMouseOut={() => setShowStepsTooltip(false)}
											>
												<SurgeryStepIcon
													step={step}
													active
													pending={isStepMissingSomething(patient, surgery, step, documentTypes)}
													badge={
														isStepErrored(patient, surgery, step, documentTypes)
															? "error"
															: isStepComplete(patient, surgery, step, documentTypes, modules)
																? "complete"
																: undefined
													}
												/>
											</Link>
										) : (
											<Link
												to={`/patient/surgery/${surgery.id}/${currentStep}`}
												onMouseOver={() => {
													setShowStepsTooltip(true);
													setStepHover(step);
												}}
												onMouseOut={() => setShowStepsTooltip(false)}
											>
												<SurgeryStepIcon step={step} />
											</Link>
										)}
									</Fragment>
								);
							}
						})}
					</Steps>
				) : null}

				{nextStep &&
					modules &&
					currentStep === SurgeryStep.ForensicDocuments &&
					surgery &&
					surgery.signEngaged == 1 &&
					surgeryNeedSignature &&
					(surgery?.patient?.legalGuardian1 == null ||
						(surgery?.patient?.legalGuardian1?.id == patient?.id && surgery.legalGuardian1Status != "Done") ||
						(surgery?.patient?.legalGuardian2?.id == patient?.id && surgery.legalGuardian2Status != "Done")) ?

					(
						<Text>{format("patient.sign.engaged")}</Text>
					) :

				nextStep &&
				modules &&
				currentStep === SurgeryStep.ForensicDocuments &&
				surgery &&
				surgeryNeedSignature &&
				(surgery?.patient?.legalGuardian1 == null ||
					(surgery?.patient?.legalGuardian1?.id == patient?.id && surgery.legalGuardian1Status != "Done") ||
					(surgery?.patient?.legalGuardian2?.id == patient?.id && surgery.legalGuardian2Status != "Done")) ? (
					<Visionner>
						<Button
						onClick={() => {
							sign(surgery).then();
							logSign();
						}}
					>
						{format("patient.forensicDocumentsStep.signButton")}
					</Button>
					<SmallArrowIcon src="/icons/arrow-external.svg" iconcolor={"#f45c73"} showed={false.toString()}/>
					<Click>Cliquez ici</Click>
					</Visionner>
				) : nextStep && modules && isStepAvailable(patient, surgery, nextStep, documentTypes, modules) ? (
					<Link to={`/patient/surgery/${surgery.id}/${nextStep}`}>
						<Button>{format("patient.header.next")}</Button>
						<Div>
							<SmallArrowIcon src="/icons/arrow-external.svg" iconcolor={"#f45c73"} showed={false.toString()}/>
							<Click>Cliquez ici</Click>
						</Div>
					</Link>
				) : currentStep === SurgeryStep.Surgery && surgery.practitioner?.evaluation == 0 ? (
					<Button onClick={() => exportIcal(surgery, surgeryNames, ccamDatabase)}>{format("patient.header.appointment.ical")}</Button>
				) : (
					<NextButtonContainer>
						<Button disabled onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}>
							{format("patient.header.next")}
						</Button>

					</NextButtonContainer>
				)}
			</Buttons>
		</Header>
	);
};

export const NextButtonContainer = styled.div`
	${row(0, "center", "center")};
	position: relative;
`;

const Header = styled.div`
	${stack("M", "flex-start", "stretch")};
`;
const Top = styled.div`
	@media ${mobile} {
		${stack("M", "flex-start", "stretch", "reverse")}
	}
	@media ${desktop} {
		${row("M", "space-between", "center")}
	}
`;

const Buttons = styled.div`
	${row("M", "space-between", "stretch")};

	@media ${mobile} {
		${row(5, "space-evenly")};
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background: ${colors.white};
		padding: ${sizes.M} 5px;
		z-index: 1000;
	}
`;
const Steps = styled.div`
	${row("S", "center", "center")};
	position: relative;
`;
const Progress = styled.div<{ pending: boolean | undefined }>`
	width: 31px;
	height: 4px;
	border-radius: 2.5px;
	background-color: ${colors.grey};
	flex-shrink: 1;
	flex-grow: 1;

	div {
		background-color: ${props => (props.pending ? colors.orange : colors.green)};
	}
`;
const ProgressFilled = styled.div`
	width: 100%;
	height: 4px;
	border-radius: 2.5px;
`;
const ProgressHalfFilled = styled.div`
	width: 50%;
	height: 4px;
	border-radius: 2.5px;
`;

const Visionner = styled.div`
	width: auto;
`;

const moveUpDown = keyframes`
  0%, 100% {
    top: 0px;
  }
  50% {
    top: 8px;
  }
`;

const SmallArrowIcon = styled(ArrowIcon)<{ iconcolor?: string }>`
	path {
		fill: ${(props: { iconcolor: any; }) => props.iconcolor ?? ""};
	}
	transform: ${props =>
	props.showed
		? css`
		 rotate(-45deg)
	`
		: null};
	margin: 5px auto 5px auto;
	position: relative;
	width: 90px;
	height: 20px;
	text-align: center;
	display: block;
  animation: ${moveUpDown} 0.8s linear infinite;
`;

const Click = styled.div`
  font-size: 12px;
	color: #f45c73;
	text-align: center;
`;

const Div = styled.div`
		width: auto;
`;

const Text = styled.div`
    max-width: 250px;
    background-color: #ffd277;
    padding: 10px;
    border-radius: 12px;
    font-size: 15px;
`;
