import React, { useState } from "react";
import styled from "@emotion/styled";
import { pdfjs } from "react-pdf";
import { useRequireAuth } from "../../utils/navigation";
import { UserType } from "../../domain/user-type";
import {  desktop, mobile, row, sizes, stack } from "../../style";
import { Helmet } from "react-helmet-async";
import { SupportTechnicalNavBar } from "../../components/navigation/support-technical-nav-bar";
import { useI18n } from "../../utils/i18n";
import { CreateDocCustomSectionSection } from "../../components/sections/create-docCustom-section";
import { supportTechnicalService } from "../../services/support-technical-service";
import { AlertComponent } from "../../components/notifications/alertComponent";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export enum PdfCustomField {
	lastNamePatient = "lastNamePatient",
	firstNamePatient = "firstNamePatient",
	birthDatePatient = "birthDatePatient",
	lastNamePractitioner = "lastNamePractitioner",
	firstNamePractitioner = "firstNamePractitioner",
	rppsCode = "rppsCode",
	prescriptionDate = "prescriptionDate",
	startDate = "startDate",
	classification_code = "classification_code",
	creation_date = "creation_date",
	checkBox = "checkBox",
	surgeryDate = "surgeryDaten",
	signature = "signature",
	fullNamePractitioner = "fullNamePractitioner",
	fullNamePatient = "fullNamePatient",
	hospitalName = "hospitalName",
	hospitalCity = "hospitalCity",
	hospitalAddress = "hospitalAddress",
	hospitalHeader = "hospitalHeader",
	ald = "ald",
	workAccident = "workAccident",
}
const PdfEditor: React.FC = () => {
	useRequireAuth(UserType.Support);
	const { format } = useI18n();
	const [showNotification, setShowNotification] = useState<boolean>(false);
	const [state, setState] = useState<string>("success");
	const [message, setMessage] = useState<string>("");
	const base64ToBlob = (base64: string, type: string) => {
		const binaryString = window.atob(base64);
		const length = binaryString.length;
		const bytes = new Uint8Array(length);
		for (let i = 0; i < length; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		return new Blob([bytes], { type });
	};
	return (
		<Pages>
			<Helmet>
				<title>{format("patient.dashboard.pageTitle")}</title>
			</Helmet>
			<Sections>
				<SupportTechnicalNavBar />
				{showNotification && 	<AlertComponent
					openAlertBox={true}
					state={state}
					message={message}
					onClose={() => setShowNotification(false)}
				/>}
				<CreateDocCustomSectionSection onEdit={async (adjustRectangles, currentFileName) => {
					const response = await supportTechnicalService.generateDocument(adjustRectangles)
					if (response.status == 200) {
						setMessage("Génération du document OK")
						setState("success")
						setShowNotification(true)
						const newFileBase64 = response.data;
						const blob = base64ToBlob(newFileBase64, 'application/pdf');
						const url = URL.createObjectURL(blob);
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', `${currentFileName}`);
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}
				}}/>
			</Sections>
		</Pages>
	);
};

const Sections = styled.div`
    ${stack("S", "flex-start", "stretch")}
`;


const Pages = styled.div`
    ${stack("XL", "flex-start", "stretch")}
    flex-grow: 1;
    min-height: 100vh;
    margin: 0 auto;
    width: 1100px;
    max-width: calc(100vw - 40px);

    @media ${desktop} {
        padding-top: ${sizes.XL};
        padding-bottom: ${sizes.XL};
    }

    @media ${mobile} {
        padding-bottom: 100px;
    }
`;

export default PdfEditor;
