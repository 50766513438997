import { AnesthesiaType } from "domain/anesthesia";
import {
	CcamDpmMode, CcamOptamType,
	hpState,
	MutualType,
	SurgeryHealthCareProvider,
	SurgeryLaterality,
	VisitType
} from "domain/surgery";
import React, { useCallback, useContext } from "react";
import { useIntl } from "react-intl";
import { WordingKey } from "./i18n-typings";
import { Role } from "../services/modal-service";
import { BlueFlag, GreenFlag, OrangeFlag, RedFlag } from "../style/flags";
import { PdfCustomField } from "../pages/support-technical/docCustom";

export function useI18n() {
	const intl = useIntl();
	const overrideContext = useContext(WordingOverrideContext);

	return {
		...intl,
		format: useCallback(
			(key: WordingKey, values?: Record<string, string | number | boolean | Date | null | undefined> | undefined) => {
				if (overrideContext?.[key]) {
					return intl.formatMessage({ id: overrideContext[key] }, { ...values }) as string;
				}
				return intl.formatMessage({ id: key }, { ...values }) as string;
			},
			[intl, overrideContext]
		),
		formatDateTime: (value?: string | number | Date) =>
			intl.formatDate(value, {
				day: "numeric",
				month: "numeric",
				year: "numeric",
				hour: "numeric",
				minute: "numeric",
			}),
		formatGender(gender: "Male" | "Female" | "Other") {
			switch (gender) {
				case "Male":
					return intl.formatMessage({ id: "gender.male" });
				case "Female":
					return intl.formatMessage({ id: "gender.female" });
				case "Other":
					return intl.formatMessage({ id: "gender.other" });
			}
		},
		formatVisitType(value: VisitType) {
			switch (value) {
				case VisitType.Inpatient:
					return intl.formatMessage({ id: "visitType.inpatient" });
				case VisitType.Outpatient:
					return intl.formatMessage({ id: "visitType.outpatient" });
				case VisitType.External:
					return intl.formatMessage({ id: "visitType.external" });
			}
		},
		formatLaterality(value: string) {
			switch (value) {
				case SurgeryLaterality.Left:
					return intl.formatMessage({ id: "laterality.left" });
				case SurgeryLaterality.Right:
					return intl.formatMessage({ id: "laterality.right" });
				case SurgeryLaterality.Both:
					return intl.formatMessage({ id: "laterality.both" });
				case SurgeryLaterality.None:
					return intl.formatMessage({ id: "laterality.none" });
				default:
					return value;
			}
		},
		formatPdfField(value: string) {
			switch (value) {
				case PdfCustomField.lastNamePatient:
					return intl.formatMessage({ id: "PdfCustomField.lastNamePatient" });
				case PdfCustomField.firstNamePatient:
					return intl.formatMessage({ id: "PdfCustomField.firstNamePatient" });
				case PdfCustomField.birthDatePatient:
					return intl.formatMessage({ id: "PdfCustomField.birthDatePatient" });
				case PdfCustomField.lastNamePractitioner:
					return intl.formatMessage({ id: "PdfCustomField.lastNamePractitioner" });
				case PdfCustomField.firstNamePractitioner:
					return intl.formatMessage({ id: "PdfCustomField.firstNamePractitioner" });
				case PdfCustomField.rppsCode:
					return intl.formatMessage({ id: "PdfCustomField.rppsCode" });
				case PdfCustomField.prescriptionDate:
					return intl.formatMessage({ id: "PdfCustomField.prescriptionDate" });
				case PdfCustomField.startDate:
					return "startDate";
				case PdfCustomField.creation_date:
					return intl.formatMessage({ id: "PdfCustomField.creation_date" });
				case PdfCustomField.classification_code:
					return intl.formatMessage({ id: "PdfCustomField.classification_code" });
				case PdfCustomField.surgeryDate:
					return intl.formatMessage({ id: "PdfCustomField.surgeryDate" });
				case PdfCustomField.signature:
					return intl.formatMessage({ id: "PdfCustomField.signature" });
				case PdfCustomField.fullNamePractitioner:
					return intl.formatMessage({ id: "PdfCustomField.fullNamePractitioner" });
				case PdfCustomField.fullNamePatient:
					return intl.formatMessage({ id: "PdfCustomField.fullNamePatient" });
				case PdfCustomField.hospitalName:
					return intl.formatMessage({ id: "PdfCustomField.hospitalName" });
				case PdfCustomField.hospitalCity:
					return intl.formatMessage({ id: "PdfCustomField.hospitalCity" });
				case PdfCustomField.hospitalAddress:
					return intl.formatMessage({ id: "PdfCustomField.hospitalAddress" });
				case PdfCustomField.hospitalHeader:
					return intl.formatMessage({ id: "PdfCustomField.hospitalHeader" });
				default:
					return value;
			}
		},
		formatAnesthesiaType(value: string) {
			switch (value) {
				case AnesthesiaType.General:
					return intl.formatMessage({ id: "anesthesia.general" });
				case AnesthesiaType.Local:
					return intl.formatMessage({ id: "anesthesia.local" });
				default:
					return value;
			}
		},
		formatMutual(value: string) {
			switch (value) {
				case MutualType.Classic:
					return intl.formatMessage({ id: "mutual.classic" });
				case MutualType.CMU:
					return intl.formatMessage({ id: "mutual.CMU" });
				case MutualType.ALD:
					return intl.formatMessage({ id: "mutual.ALD" });
				default:
					return value;
			}
		},
		formatRole(value: string) {
			switch (value) {
				case Role.Secretary:
					return intl.formatMessage({ id: "role.secretary" });
				case Role.Practitioner:
					return intl.formatMessage({ id: "role.practitioner" });
				case Role.Hpu:
					return intl.formatMessage({ id: "role.hpu" });
				case Role.HospitalUser:
					return intl.formatMessage({ id: "role.hospital.user" });
				default:
					return value;
			}
		},
		formatHealthCareProviderReason(value: string) {
			switch (value) {
				case SurgeryHealthCareProvider.Healing:
					return intl.formatMessage({ id: "healthcareProvider.healing" });
				case SurgeryHealthCareProvider.Perfusion:
					return intl.formatMessage({ id: "healthcareProvider.perfusion" });
				case SurgeryHealthCareProvider.Respiratory:
					return intl.formatMessage({ id: "healthcareProvider.respiratory" });
				case SurgeryHealthCareProvider.Analgesia:
					return intl.formatMessage({ id: "healthcareProvider.analgesia" });
				case SurgeryHealthCareProvider.Nutrition:
					return intl.formatMessage({ id: "healthcareProvider.nutrition" });
				case SurgeryHealthCareProvider.InsulinTherapy:
					return intl.formatMessage({ id: "healthcareProvider.insulinTherapy" });
				default:
					return value;
			}
		},
		formatHpState(value: string) {
			switch (value) {
				case hpState.DOCUMENTREJECTED:
					return <RedFlag>{intl.formatMessage({ id: "hpu.state.document.rejected" })}</RedFlag>;
				case hpState.DOCUMENTWITHOUTSIGN:
					return <BlueFlag>{intl.formatMessage({ id: "hpu.state.document.without.sign" })}</BlueFlag>;
				case hpState.DOCUMENTSIGNED:
					return (
						<GreenFlag withIcon withBackgroundColor>
							{intl.formatMessage({ id: "hpu.state.document.signed" })}
						</GreenFlag>
					);
				case hpState.INFOADD:
					return (
						<OrangeFlag>
							{intl.formatMessage({ id: "hpu.state.document.info.add" })}
						</OrangeFlag>
					);
			}
		},
		formatDpmMode(value: string) {
			switch (value) {
				case CcamDpmMode.NONE:
					return intl.formatMessage({ id: "dpmMode.none" });
				case CcamDpmMode.FIXED_AMOUNT:
					return intl.formatMessage({ id: "dpmMode.fixedAmount" });
				case CcamDpmMode.PERCENTAGE:
					return intl.formatMessage({ id: "dpmMode.percentage" });
				case CcamDpmMode.TOTAL_AMOUNT:
					return intl.formatMessage({ id: "dpmMode.totalAmount" });
				default:
					return value;
			}
		},
		formatOptamType(value: string) {
			switch (value) {
				case CcamOptamType.NONE:
					return intl.formatMessage({ id: "optamType.none" });
				case CcamOptamType.OPTAM:
					return intl.formatMessage({ id: "optamType.optam" });
				case CcamOptamType.NO_OPTAM:
					return intl.formatMessage({ id: "optamType.noOptam" });
				default:
					return value;
			}
		},
	};
}

export function capitalize(str: string) {
	return (str[0] ?? "").toUpperCase() + str.slice(1);
}

export function capitalizeFirstName(str: string) {
	const splitStr = str.toLowerCase().split(RegExp("([\\-| ])"));
	for (let i = 0; i < splitStr.length; i++) {
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	return splitStr.filter(element => element != " ").join("-");
}

const WordingOverrideContext = React.createContext<Partial<Record<WordingKey, WordingKey>> | null>(null);

export const OverrideWording: React.FC<{ wording: Partial<Record<WordingKey, WordingKey>>, children?: React.ReactNode }> = ({
	wording,
	children,
}) => {
	return <WordingOverrideContext.Provider value={wording}>{children}</WordingOverrideContext.Provider>;
};
