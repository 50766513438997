import AddIcon from "@assets/icons/add-square.svg";
import styled from "@emotion/styled";
import { TypeChecking, Uppy, UppyFile } from "@uppy/core";
import { DragDrop, ProgressBar } from "@uppy/react";
import { XHRUploadOptions } from "@uppy/xhr-upload";
import { Button } from "components/button";
import { motion } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDocumentType, useDocumentTypes, useDocumentTypesHealthCareProvider } from "services/document-type-service";
import { colors, mobile, row, sizes, Spacer, stack } from "style";
import { useStateWithEffect } from "utils/hooks";
import { useI18n } from "utils/i18n";
import { CheckboxInput } from "../inputs/checkbox-input";
import { SelectInput, SelectInputType } from "../inputs/select-input";
import { DocumentDescriptionEditor } from "./document-description-editor";
import { Surgery } from "../../domain/surgery";
import { SurgeryHPU } from "../../domain/hpu";
import { DocumentType } from "../../domain/document";
import { practitionerService } from "../../services/practitioner-service";
import { secretaryService } from "../../services/secretary-service";
import { patientService } from "../../services/patient-service";
import { hpuService } from "../../services/hpu-service";
import { useUser } from "../../services/auth-service";
import { UserType } from "../../domain/user-type";

type DocumentUploaderProps = {
	uppyEndpointFactory: () => XHRUploadOptions;
	documentTypeId?: string;
	documentTypeIds?: string[];
	disabledTypeIds?: string[];
	onError?: (response?: boolean) => void;
	onSuccess?: () => void;
	allowMultipleFiles?: boolean;
	showSignatureNeededCheckbox?: boolean;
	showToPrintCheckbox?: boolean;
	onDocumentCustomTypeSelected?: (docType: DocumentType) => void;
	rejected?: boolean;
	practitionerId?: string;
	surgery: Surgery | SurgeryHPU;
	hpu: boolean;
};

export type UploadResponse = {
	body: { message?: string };
	status: number;
	uploadURL?: string;
};

interface FileMeta {
	needSignature: boolean;
	toPrint: boolean;
	documentTypeId: string | null;
	description: string | null;
}

const allowedFileTypes = [
	{ extension: ".pdf", mime: "application/pdf" },
	{ extension: ".jpeg", mime: "image/jpeg" },
	{ extension: ".jpg", mime: "image/jpeg" },
	{ extension: ".png", mime: "image/png" },
];
function checkFileMimeType(file: UppyFile) {
	return allowedFileTypes.map(type => type.mime).includes(file.type ?? "");
}

export const DocumentUploader = React.forwardRef<HTMLDivElement, DocumentUploaderProps>(
	(
		{
			documentTypeId,
			documentTypeIds,
			disabledTypeIds,
			uppyEndpointFactory,
			onSuccess,
			onError,
			allowMultipleFiles,
			showSignatureNeededCheckbox,
			showToPrintCheckbox,
			onDocumentCustomTypeSelected,
			rejected,
			practitionerId,
			surgery,
			hpu,
		},
		ref
	) => {
		const [files, setFiles] = useState<UppyFile[]>([]);
		const [error, setError] = useState<string | null>(null);
		const meta = useRef<Partial<FileMeta>>({});
		const { format } = useI18n();
		const user = useUser();
		const { uppy, uploading, upload } = useUppy(uppyEndpointFactory, {
			checkFile: newFile => {
				setError(null);
				if (!checkFileMimeType(newFile)) {
					alert(format("documentUpload.wrongType"));
					return false;
				}
				return newFile;
			},
			onFileAdded: file => {
				setFiles(files => [...files, file]);
			},
			onInit: uppy => {
				if (documentTypeId) {
					meta.current = { documentTypeId, needSignature: needsSignature, toPrint: needsToPrint };
					uppy.setMeta(meta.current);
				}
			},
			onSuccess: () => {
				setFiles([]);
				setSelectedDocumentType(null);
				setNeedsSignature(false);
				setNeedsToPrint(false);
				setDescription(null);
				onSuccess?.();
				uppy?.reset();

				if (user?.type == UserType.Practitioner) {
					practitionerService.fetchSurgery(surgery.id).then();
				}
				if (user?.type == UserType.Secretary) {
					secretaryService.fetchSurgery(surgery.id, surgery.practitionerId as string).then();
				}
				if (user?.type == UserType.Hpu) {
					hpuService.fetchSurgery(surgery.id).then();
				}
				if (user?.type == UserType.Patient) {
					patientService.fetchSurgery(surgery.id).then();
				}
			},
			onError: (error: Error) => {
				onError?.(error.message.includes("Bad Request"));
			},
		});

		const setMetaEffect = useCallback(
			(value: boolean | number | string | null, metaKey: keyof FileMeta) => {
				const updateObject = (object: Record<string, unknown>) =>
					Object.fromEntries([
						...Object.entries(object).filter(([key]) => key !== metaKey),
						...(value === false || value === true || value ? [[metaKey, value]] : []),
					]);
				meta.current = updateObject(meta.current);
				uppy?.setState({
					meta: meta.current,
				});
				uppy?.getFiles().forEach(file => uppy.setFileState(file.id, { meta: updateObject(file.meta) }));
			},
			[uppy]
		);
		const [needsSignature, setNeedsSignature] = useStateWithEffect<boolean>(false, value =>
			setMetaEffect(value ? 1 : 0, "needSignature")
		);
		const [needsToPrint, setNeedsToPrint] = useStateWithEffect<boolean>(false, value =>
			setMetaEffect(value, "toPrint")
		);
		const [description, setDescription] = useStateWithEffect<string | null>(null, value =>
			setMetaEffect(value, "description")
		);
		const [selectedDocumentType, setSelectedDocumentType] = useStateWithEffect<string | null>(null, value =>
			setMetaEffect(value, "documentTypeId")
		);
		const resetFiles = useCallback(() => {
			uppy?.cancelAll();
			setFiles([]);
			setSelectedDocumentType(null);
			setNeedsSignature(false);
			setNeedsToPrint(false);
			setDescription(null);
			setUppyWindow(false);
		}, [setDescription, setNeedsSignature, setSelectedDocumentType, uppy, setNeedsToPrint]); //

		const [uppyWindow, setUppyWindow] = useState<boolean>(false);
		const [weblinkWindow, setWeblinkWindow] = useState<boolean>(false);
		if (uploading) {
			return <UploadingContent ref={ref} uppy={uppy} files={files} onDelete={resetFiles} />;
		} else {
			return !uppyWindow && !weblinkWindow ? (
				<RowButton>
					<CustomButton type="button" onClick={() => setUppyWindow(true)} style={{ alignSelf: "flex-end" }}>
						<div style={{ fontSize: "13px", lineHeight: "1em" }}>{format("practitioner.surgery.upload.addFile")}</div>
					</CustomButton>

				</RowButton>
			) : (
				<FilledContent
					ref={ref}
					uppy={uppy}
					files={files}
					forcedDocumentTypeId={documentTypeId}
					selectedDocumentTypeId={selectedDocumentType}
					documentTypeIds={documentTypeIds}
					disabledTypeIds={disabledTypeIds}
					allowMultipleFiles={allowMultipleFiles}
					showSignatureNeededCheckbox={showSignatureNeededCheckbox}
					showToPrintCheckbox={showToPrintCheckbox}
					onDelete={resetFiles}
					onUpload={upload}
					onDocumentTypeIdChange={setSelectedDocumentType}
					onNeedsSignatureChange={setNeedsSignature}
					onNeedsToPrint={setNeedsToPrint}
					needsSignature={needsSignature}
					needsToPrint={needsToPrint}
					onDescriptionChange={setDescription}
					onDocumentCustomTypeSelected={docType => {
						if (onDocumentCustomTypeSelected) {
							onDocumentCustomTypeSelected(docType);
						}
					}}
					description={description}
					error={error}
					rejected={rejected}
					surgery={surgery}
					practitionerId={practitionerId}
					hpu={hpu}
				/>
			);
		}
	}
);
DocumentUploader.displayName="DocumentUploader";

const AddDocument = React.forwardRef<
	HTMLDivElement,
	{
		uppy: Uppy | null;
		error: string | null;
		documentTypeId?: string;
		rejected?: boolean;
		surgery: Surgery | SurgeryHPU;
		onSelectDocCustom?: (docType: DocumentType | undefined) => void;
		practitionerId?: string;
		hpu?: boolean;
	}
>(({ uppy, error, documentTypeId, surgery, onSelectDocCustom, practitionerId }, ref) => {
	const { format } = useI18n();
	const inputDocType = useDocumentType(documentTypeId);

	if (inputDocType?.description != "Personnalisable") {
		return (
			<ContainerCustom ref={ref}>
				{uppy && (
					<DragAndDropOnTop>
						<DragDrop uppy={uppy} />
					</DragAndDropOnTop>
				)}
				<UploadButton animate>{format("practitioner.surgery.upload.add")}</UploadButton>
				{error && <ErrorMessage>{error}</ErrorMessage>}
			</ContainerCustom>
		);
	} else {
		return (
			<ContainerCustom ref={ref}>
				<UploadButton
					animate
					onClick={() => {
						onSelectDocCustom?.(inputDocType);
						practitionerId
							? secretaryService.fetchDocCustom(surgery.id, practitionerId as string).then()
							: practitionerService.fetchDocCustom(surgery.id).then();
					}}
				>
					{format("practitioner.surgery.upload.add")}
				</UploadButton>
				{error && <ErrorMessage>{error}</ErrorMessage>}
			</ContainerCustom>
		);
	}
});
AddDocument.displayName = "AddDocument";

const UploadingContent = React.forwardRef<
	HTMLDivElement,
	{ uppy: Uppy | null; files: UppyFile[]; onDelete?: () => void }
>(({ uppy, files, onDelete }, ref) => {
	const { format } = useI18n();

	return (
		<Container ref={ref} animate style={{ alignItems: "center" }}>
			<RemoveFileIcon src="/icons/close.svg" onClick={onDelete} />
			<Spacer space={2} />
			<PdfIcon src="/icons/pdf.svg" />
			<FileName>
				{files.length === 1 ? files[0].name : format("documentUpload.multipleFiles", { count: files.length })}
			</FileName>
			<Spacer />
			<ProgressBarContainer>{uppy && <ProgressBar uppy={uppy} />}</ProgressBarContainer>
			<Spacer />
		</Container>
	);
});
UploadingContent.displayName = "UploadingContent";

const FilledContent = React.forwardRef<
	HTMLDivElement,
	{
		files: UppyFile[];
		uppy: Uppy | null;
		forcedDocumentTypeId?: string;
		selectedDocumentTypeId: string | null;
		documentTypeIds?: string[];
		disabledTypeIds?: string[];
		allowMultipleFiles?: boolean;
		onDelete?: () => void;
		onFileChange?: (file: UppyFile) => void;
		onUpload?: () => void;
		showSignatureNeededCheckbox?: boolean;
		showToPrintCheckbox?: boolean;
		onDocumentTypeIdChange: (value: string) => void;
		needsSignature: boolean;
		needsToPrint: boolean;
		onNeedsSignatureChange: (value: boolean) => void;
		onNeedsToPrint: (value: boolean) => void;
		description: string | null;
		onDescriptionChange: (value: string | null) => void;
		onDocumentCustomTypeSelected?: (docType: DocumentType) => void;
		error: string | null;
		rejected?: boolean;
		practitionerId?: string;
		surgery: Surgery | SurgeryHPU;
		hpu?: boolean;
	}
>(
	(
		{
			files,
			selectedDocumentTypeId,
			forcedDocumentTypeId,
			documentTypeIds,
			disabledTypeIds,
			showSignatureNeededCheckbox,
			showToPrintCheckbox,
			allowMultipleFiles,
			onUpload,
			onDelete,
			onDocumentTypeIdChange,
			needsSignature,
			needsToPrint,
			onNeedsSignatureChange,
			onNeedsToPrint,
			uppy,
			description,
			onDescriptionChange,
			onDocumentCustomTypeSelected,
			error,
			rejected,
			practitionerId,
			surgery,
			hpu,
		},
		ref
	) => {
		const { format } = useI18n();
		const selectedDocumentType = useDocumentType(selectedDocumentTypeId);
		const { documentTypes } = useDocumentTypes();
		const { documentTypesHPU } = useDocumentTypesHealthCareProvider();
		const getDocumentName = useCallback(
			(docTypeId: string) =>
				documentTypes.find(doc => docTypeId && doc.id === docTypeId)?.label ??
				documentTypesHPU.find(doc => docTypeId && doc.id === docTypeId)?.label ??
				"",
			[documentTypes, documentTypesHPU]
		);
		useEffect(() => {
			if (selectedDocumentTypeId) {
				onNeedsSignatureChange(!!selectedDocumentType?.isToBeSigned);
			}
		}, [selectedDocumentType?.isToBeSigned, selectedDocumentTypeId]);
		return (
			<Container ref={ref} animate transition={{ delay: 3 }}>
				<Content initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.25 }}>
					<RemoveFileIcon src="/icons/close.svg" onClick={onDelete} />
					<Spacer space={2} />
					<PdfIcon src="/icons/pdf.svg" />
					<FileName>
						{files.length === 1 ? files[0].name : format("documentUpload.multipleFiles", { count: files.length })}
					</FileName>
					<Spacer />
					{forcedDocumentTypeId ? (
						<DocumentTypeLabel>{getDocumentName(forcedDocumentTypeId)}</DocumentTypeLabel>
					) : documentTypeIds ? (
						<>
							<FileTypeSelectInput
								placeholder={format("practitioner.surgery.document.placeholder")}
								value={selectedDocumentTypeId}
								innerId="file-type-selectfield"
								options={documentTypeIds}
								disabledOptions={disabledTypeIds}
								itemRenderer={type => getDocumentName(type)}
								onChange={type => onDocumentTypeIdChange?.(type)}
							/>
							{selectedDocumentTypeId && (
								<DocumentDescriptionEditor
									documentTypeId={selectedDocumentTypeId}
									value={description}
									onChange={description => onDescriptionChange(description)}
								/>
							)}
						</>
					) : null}
					<Spacer />
					<BottomContainer>
						{showSignatureNeededCheckbox && (
							<SigningButton value={needsSignature} onChange={onNeedsSignatureChange}>
								<SigningText>{format("practitioner.surgery.upload.toSign")}</SigningText>
							</SigningButton>
						)}
						{showToPrintCheckbox && (
							<SigningButton value={needsToPrint} onChange={onNeedsToPrint}>
								<SigningText>{format("practitioner.surgery.upload.toPrint")}</SigningText>
							</SigningButton>
						)}
					</BottomContainer>
					<BottomContainer>
						{allowMultipleFiles && (
							<div style={{ position: "relative" }}>
								{uppy && (
									<DragAndDropOnTop>
										<DragDrop uppy={uppy} />
									</DragAndDropOnTop>
								)}
								<UploadButton secondary>{format("documentUpload.add")}</UploadButton>
							</div>
						)}
						{files.length === 0 ? (
							<AddDocument
								ref={ref}
								uppy={uppy}
								error={error}
								documentTypeId={selectedDocumentType?.id}
								rejected={rejected}
								surgery={surgery}
								onSelectDocCustom={docType => {
									if (onDocumentCustomTypeSelected && docType) {
										onDocumentCustomTypeSelected(docType);
									}
								}}
								practitionerId={practitionerId}
								hpu={hpu}
							/>
						) : (
							<UploadButton
								onClick={onUpload}
							>
								{format("practitioner.surgery.upload.validate")}
							</UploadButton>
						)}
					</BottomContainer>
				</Content>
			</Container>
		);
	}
);
FilledContent.displayName = "FilledContent";

function useUppy(
	endpointConfigFactory: () => XHRUploadOptions,
	options?: {
		checkFile?: (currentFile: UppyFile, files: { [key: string]: UppyFile }) => boolean | UppyFile | undefined;
		onSuccess?: () => void;
		onInit?: (uppy: Uppy) => void;
		onFileAdded: (files: UppyFile) => void;
		onError?: (error: Error) => void;
	}
) {
	const [uppy, setUppy] = useState<Uppy<TypeChecking> | null>(null);
	const [uploading, setUploading] = useState(false);
	const uppyRef = useRef<Uppy | null>(null);

	useEffect(() => {
		async function initUppy() {
			const { Uppy } = await import("@uppy/core");
			const XHRUpload = await import("@uppy/xhr-upload");
			const newUppy = new Uppy({
				id: "uppy1",
				autoProceed: false,
				restrictions: { allowedFileTypes: allowedFileTypes.map(type => type.extension) },
				onBeforeFileAdded: options?.checkFile,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			}).use(XHRUpload as any);
			if (options?.onFileAdded) {
				newUppy.on("file-added", options.onFileAdded);
			}
			setUppy(newUppy);
			uppyRef.current = newUppy;
			options?.onInit?.(newUppy);
		}
		initUppy();

		return () => {
			if (options?.onFileAdded) {
				uppyRef.current?.off("file-added", options.onFileAdded);
			}
			uppyRef.current?.close();
			setUppy(null);
		};
		// We don't want to rebuild Uppy if deps change for now
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const upload = useCallback(async () => {
		if (!uppy) {
			throw new Error("Uppy was not initialized");
		}
		setUploading(true);
		try {
			uppy.getPlugin("XHRUpload").setOptions(endpointConfigFactory());
			await uppy.upload();
			const response = uppy.getFiles()?.[0].response;

			if (response && Math.floor(response.status / 100) === 2) {
				options?.onSuccess?.();
			} else {
				options?.onError?.(new Error(JSON.stringify(response)));
			}
		} finally {
			setUploading(false);
		}
	}, [options, uppy, endpointConfigFactory]);

	return {
		uppy,
		uploading,
		upload,
	};
}

const Container = styled(motion.div)<{ row?: boolean }>`
	${props => (props.row ? row("S", "center", "center") : stack("S", "center", "stretch"))};
	position: relative;
	width: ${props => (props.row ? "208px" : "265px")};
	height: ${props => (props.row ? "49px" : "300px")};
	padding: ${props => (props.row ? sizes.S : sizes.M)};
	background-color: ${props => (props.row ? `${colors.pink}` : `${colors.white}`)};
	border-radius: 30px;
	box-shadow: ${props => (props.row ? "" : "0 2px 30px 0 rgba(0, 0, 0, 0.08)")};
`;

const ContainerCustom = styled(motion.div)<{ row?: boolean }>`
	box-shadow: ${props => (props.row ? "" : "0 2px 30px 0 rgba(0, 0, 0, 0.08)")};
`;

const Content = styled(motion.div)<{ row?: boolean }>`
	${props => (props.row ? row("S", "flex-start", "center") : stack("S", "center", "center"))};
	flex-grow: 1;
`;

const DragAndDropOnTop = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 10;

	> * {
		width: 100%;
		height: 100%;
	}

	* {
		cursor: pointer;
		opacity: 0;
	}
`;

export const SmallAddIcon = styled(AddIcon)<{ iconcolor?: string }>`
	width: 24px;
	path {
		fill: ${props => props.iconcolor ?? ""};
	}
`;

const RemoveFileIcon = styled.img`
	width: 24px;
	height: 24px;
	position: absolute;
	padding: 5px;
	top: 0;
	right: 0;
	box-sizing: content-box;
	cursor: pointer;
	z-index: 10;
	transition: transform 0.1s ease-out;

	&:hover {
		transform: scale(1.1);
	}
`;

const PdfIcon = styled.img`
	width: 24px;
	height: 30px;
`;

const FileName = styled.span`
	color: ${colors.black};
	font-size: 13px;
	font-style: italic;
	text-align: center;
`;

const SigningText = styled.span`
	color: ${colors.black};
	font-size: 13px;
`;

const DocumentTypeLabel = styled.div`
	font-size: 13px;
	color: ${colors.grey2};
`;

const BottomContainer = styled.div`
	${row("S", "space-between", "center")};
	width: 100%;
`;

const UploadButton = styled(Button)`
	padding: 3px 11px;
	height: unset;
	border-radius: 9px;

	:disabled {
		cursor: default;
		opacity: 0.5;
	}
`;

const SigningButton = styled(CheckboxInput)``;

const FileTypeSelectInput = styled<SelectInputType<string>>(SelectInput)`
	flex-grow: unset;
	flex-shrink: unset;

	select {
		width: 200px;
		font-size: 13px;
		padding-left: 8px;
	}

	img {
		width: 12px;
		height: 12px;
	}
`;

const ProgressBarContainer = styled.div`
	width: 100%;

	> * {
		width: 100%;
	}
`;

const ErrorMessage = styled.div`
	max-width: 240px;
	color: ${colors.red};
	font-size: 15px;
	text-align: center;
`;

const CustomButton = styled(Button)`
	@media ${mobile} {
		max-width: 64px;
	}
`;
const RowButton = styled.div`
	${row("S", "flex-start", "flex-start")};
`;
