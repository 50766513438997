import styled from "@emotion/styled";
import { Button } from "components/button";
import { SectionTitle } from "components/section";
import { motion } from "framer-motion";
import React, { useCallback, useState } from "react";
import { row, stack } from "style";
import { useI18n } from "utils/i18n";
import { CheckboxMultiSelect } from "../inputs/multi-select-input";
import { TextInput } from "../inputs/text-input";
import {
	supportTechnicalService,
	useModules,
	usePractitionersForSettings,
} from "../../services/support-technical-service";

interface HospitalSectionProps {
	onEdit?: (data: {showNotification: boolean, state: string, message: string}) => void;
}
export const CreateHospitalSection: React.FC<HospitalSectionProps> = ({onEdit}) => {
	const { format } = useI18n();
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const [name, setName] = useState<string>("");
	const [city, setCity] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const {practitioners} = usePractitionersForSettings()
	const {modules} = useModules()
	const [practitionersValue, setPractitionersValue] = useState<string[]>([]);
	const [modulesValue, setModulesValue] = useState<string[]>([]);

	const createHospital = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
		try{
			event?.preventDefault();
			setLoading(!loading)
			const response = await supportTechnicalService.createHospital(phoneNumber, name, city, practitionersValue, modulesValue)
			setLoading(false);
			onEdit?.({ showNotification: true, state: "success", message: `Success: ${response.data.message}` });
		}
		catch (e: any) {
			e?.response?.status;
			setLoading(false);
			onEdit?.({ showNotification: true, state: "danger", message: `Erreur : ${e?.response?.data.message || "Une erreur inconnue est survenue"}` });
		}
		finally {
			setLoading(false);
			setPhoneNumber("");
			setName("");
			setCity("");
			setPractitionersValue([]);
			setModulesValue([]);
		}

	}, [loading, modulesValue, phoneNumber, name, city, practitionersValue, onEdit])


	return (
		<Form onSubmit={createHospital}>
			<SectionTitle layout>{format("create.hospital")}</SectionTitle>
			<Row>
				<Input
					value={name}
					placeholder={format("surgeryVisitSection.hci.label")}
					onChange={e => {
						setName(e.target.value.toUpperCase());
					}}
					required
				/>
				<Input
					value={phoneNumber}
					type="tel"
					placeholder={format("secretary.contact.phoneNumber1")}
					onChange={e => {
						setPhoneNumber(e.target.value);
					}}
					required
				/>
				<Input
					value={city}
					type="text"
					placeholder={format("patient.info.pharmacy.other.placeholder.city")}
					onChange={e => {
						setCity(e.target.value);
					}}
					title="Le nom de la ville où se situe l'établissement"
					required
				/>
			</Row>
			<Row>
				<CheckboxMultiSelect<string>
					innerId="practitioners"
					values={practitionersValue ?? []}
					onChange={(e) => setPractitionersValue(e)}
					defaultValue="Sélectionner un praticien à ajouter"
					options={practitioners
						?.sort((prA, prB) => prA.lastName.localeCompare(prB.lastName))
						.map(pr => pr.id) ?? []}
					itemRenderer={id => `Dr ${practitioners?.find(pr => pr.id === id)?.lastName}` ?? ""}
				/>
			</Row>
			<Row>
				<CheckboxMultiSelect<string>
					innerId="modules"
					values={modulesValue ?? []}
					onChange={(e) => setModulesValue(e)}
					defaultValue="Sélectionner un module à ajouter"
					options={modules
						?.sort((moA, moB) => moA.name.localeCompare(moB.name))
						.map(mo => mo.id) ?? []}
					itemRenderer={id => `${modules?.find(mo => mo.id === id)?.name}` ?? ""}
				/>
			</Row>
			<Buttons>
				<Button
					key="submit"
					type="submit"
					initial={{ opacity: 1 }}
					loading={loading}
					disabled={loading}
				>
					{format("patient.dashboard.info.submit")}
				</Button>
			</Buttons>
		</Form>
	);
};

const Form = styled.form`
	${stack("L", "flex-start", "stretch")};
	flex-grow: 1;
`;

const Input = styled(TextInput)`
	width: 300px
`;

const Buttons = styled(motion.div)`
	align-self: flex-end;
	${row("S", "flex-end", "center")};
`;

const Row = styled.div`
    ${row("M", "flex-start", "flex-start")};
`;
