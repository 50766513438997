import { AnesthesiaType } from "domain/anesthesia";
import { CCAM, Module, Practitioner, PractitionerDpi, Surgery, SurgeryStatus, SurgeryUpdateDTO } from "domain/surgery";
import { Map } from "immutable";
import { useComputedObservable, useObservable, WritableObservable } from "micro-observables";
import { useCallback, useEffect, useState } from "react";
import { useUser } from "services/auth-service";
import { Paginated, PaginationOptions, SortOption } from "utils/pagination";
import { api, buildPaginationParams, buildSearchParams } from "./api";
import { UserType } from "../domain/user-type";
import { CredentialsUpdate } from "../domain/utils";

export const HOSPITAL_SURGERY_PAGE_SIZE = 70;
export const FIRST_HOSPITAL_SURGERY_PAGE = { page: 0, size: HOSPITAL_SURGERY_PAGE_SIZE };

export class HospitalService {
	profile = new WritableObservable<{ id: string; name: string; hospitalName: string; hospitalId: string } | null>(null);
	surgeries = new WritableObservable<Map<number, Paginated<Surgery>>>(Map());
	surgeryByIds = new WritableObservable<Map<string, Surgery>>(Map());
	modulesByHospitalId = new WritableObservable<Map<string, Module[]>>(Map());
	practitioners = new WritableObservable<Map<string, Practitioner[]>>(Map());
	practitionersDpi = new WritableObservable<Map<string, PractitionerDpi[]>>(Map());
	ccams = new WritableObservable<CCAM[]>([]);

	public hospitalCredentials = new WritableObservable<{
		newPassword: string | undefined;
		newPasswordConfirmation: string | undefined;
		currentPassword: string | undefined;
	} | null>(null);
	public practitioner = new WritableObservable<Practitioner | null>(null);

	async fetchSurgeries(
		pageOptions: PaginationOptions,
		selectedPractitioner: string | null,
		sortOptions?: SortOption[],
		search?: string,
		anesthesiaType?: AnesthesiaType,
		active?: boolean,
		future?: boolean
	): Promise<Paginated<Surgery>> {
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
			anesthesiaType,
			...(future ? { future: "true" } : {}),
			...(active ? { status: SurgeryStatus.Active } : {}),
			...(selectedPractitioner ? { selectedPractitionerId: selectedPractitioner } : {}),
		};
		const params = buildSearchParams(paramsObject, sortOptions);
		const surgeryPage = await api.get<Paginated<Surgery>>(`hospital/surgery`, {
			params,
		});
		this.surgeries.update(surgeryPages => surgeryPages.set(surgeryPage.data.page, surgeryPage.data));
		// console.log(surgeryPage.data);
		return surgeryPage.data;
	}

	async fetchProfile(): Promise<{ hospitalName: string; name: string; hospitalId: string }> {
		const profile = await api.get<{ id: string; hospitalName: string; name: string; hospitalId: string }>(
			"hospital/me"
		);
		this.profile.set(profile.data);
		return profile.data;
	}

	async fetchSurgery(surgeryId: string): Promise<void> {
		const surgery = await api.get<Surgery>(`/hospital/surgery/${surgeryId}`);
		this.surgeryByIds.update(surgeryMap => surgeryMap.set(surgery.data.id, surgery.data));
	}

	async updateSurgery(surgeryId: string, practitionerId: string, updateSurgery: SurgeryUpdateDTO): Promise<void> {
		const surgery = await api.post<Surgery>(
			`/hospital/practitioner/${practitionerId}/surgery/${surgeryId}`,
			updateSurgery
		);
		this.surgeryByIds.update(surgeryMap => surgeryMap.set(surgery.data.id, surgery.data));
	}

	async validateDocument(surgeryId: string, documentId: string) {
		await api.post<Surgery>(`/hospital/surgery/${surgeryId}/document/${documentId}/validate`);
		await this.fetchSurgery(surgeryId);
	}

	async rejectDocument(surgeryId: string, documentId: string, rejectionMessage: string) {
		await api.post<Surgery>(`/hospital/surgery/${surgeryId}/document/${documentId}/reject`, {
			rejectionMessage,
		});
		await this.fetchSurgery(surgeryId);
	}

	async cancelDocumentStatus(surgeryId: string, documentId: string) {
		await api.post<Surgery>(`/hospital/surgery/${surgeryId}/document/${documentId}/cancel`);
		await this.fetchSurgery(surgeryId);
	}

	async log(surgeryId: string, documentId: string) {
		await api.get(`/hospital/log/surgery/${surgeryId}/document/${documentId}`);
	}
	async fetchPractitioner(practitionerId: string): Promise<Practitioner> {
		const practitioner = await api.get<Practitioner>(`/hospital/practitioner/${practitionerId}`);
		this.practitioner.update(() => practitioner.data);
		return practitioner.data;
	}

	async fetchSurgeryHospitalModulesPractitioner(hospitalId: string): Promise<Module[]> {
		const modules = await api.get<Module[]>(`/practitioner/hospital/${hospitalId}/modules`);
		this.modulesByHospitalId.update(modulesMap => modulesMap.set(hospitalId, modules.data));
		return modules.data;
	}

	async fetchSurgeryHospitalModulesPatient(hospitalId: string): Promise<Module[]> {
		const modules = await api.get<Module[]>(`/patient/hospital/${hospitalId}/modules`);
		this.modulesByHospitalId.update(modulesMap => modulesMap.set(hospitalId, modules.data));
		return modules.data;
	}

	async fetchSurgeryHospitalModulesSecretary(hospitalId: string): Promise<Module[]> {
		const modules = await api.get<Module[]>(`/secretary/hospital/${hospitalId}/modules`);
		this.modulesByHospitalId.update(modulesMap => modulesMap.set(hospitalId, modules.data));
		return modules.data;
	}

	async fetchSurgeryHospitalModulesHospital(hospitalId: string): Promise<Module[]> {
		const modules = await api.get<Module[]>(`/hospital/${hospitalId}/modules`);
		this.modulesByHospitalId.update(modulesMap => modulesMap.set(hospitalId, modules.data));
		return modules.data;
	}

	async updatePassword(
		hospitalCredentials: CredentialsUpdate | null,
		patch: Partial<Omit<CredentialsUpdate, "assign">>
	) {
		const newCredentials = await api.post<CredentialsUpdate>("hospital/password-update", {
			newPassword: hospitalCredentials?.newPassword,
			newPasswordConfirmation: hospitalCredentials?.newPasswordConfirmation,
			currentPassword: hospitalCredentials?.currentPassword,
			...patch,
		});
		this.hospitalCredentials.update(hospitalMap => (hospitalMap ? newCredentials.data : null));
	}
	async getDocumentUrl(surgeryId: string, documentId: string | undefined) {
		if (surgeryId && documentId) {
			try {
				const response = await api.get(`/hospital/surgery/${surgeryId}/document/${documentId}`, {
					responseType: 'blob', // Important: Indique que la réponse doit être traitée comme un Blob
				});

				// Créer un Blob à partir de la réponse
				const blob = new Blob([response.data], { type: 'application/pdf' }); // Spécifiez le type MIME correct si nécessaire

				// Créer une URL pour le Blob
				const url = window.URL.createObjectURL(blob);

				// Créer un lien temporaire pour déclencher le téléchargement
				const a = document.createElement('a');
				a.href = url;
				a.download = `${documentId}.pdf`; // Vous pouvez personnaliser le nom du fichier ici
				document.body.appendChild(a);
				a.click();

				// Nettoyer
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			} catch (error) {
				console.error("Erreur lors du téléchargement du document:", error);
			}
		}
	}

	async getDocumentUrlText(surgeryId: string, documentId: string | undefined) {
		if (surgeryId && documentId) {
			const response = await api.get(`/hospital/surgery/${surgeryId}/document/${documentId}/download`, {
				responseType: 'blob', // Important: Indique que la réponse doit être traitée comme un Blob
			})
			// Créer un Blob à partir de la réponse
			const blob = new Blob([response.data], { type: 'application/pdf' }); // Spécifiez le type MIME correct si nécessaire

			// Créer une URL pour le Blob
			return window.URL.createObjectURL(blob)
		}
	}

	async fetchPractitionersByHospital(hospitalId: string | null | undefined): Promise<Practitioner[]> {
		const practitioners = await api.get<Practitioner[]>(`hospital/${hospitalId}/practitioners`);
		if (hospitalId) this.practitioners.update(practitioner => practitioner.set(hospitalId, practitioners.data));
		return practitioners.data;
	}

	async fetchPractitionersDpiByHospital(hospitalId: string | null | undefined): Promise<PractitionerDpi[]> {
		const practitionersDpi = await api.get<PractitionerDpi[]>(
			`service/interop/hospital/${hospitalId}/practitionersDpi`
		);
		if (hospitalId) this.practitionersDpi.update(practitioner => practitioner.set(hospitalId, practitionersDpi.data));
		return practitionersDpi.data;
	}

	async fetchCCAMS(): Promise<CCAM[]> {
		const ccamData = await api.get<CCAM[]>(`/hospital/ccam`);
		this.ccams.update(() => ccamData.data);
		return ccamData.data;
	}
}

export const hospitalService = new HospitalService();

export function useHospitalSurgeries(
	pageOptions: PaginationOptions,
	sortOption: SortOption | null,
	selectedPractitioner: string | null,
	search?: string,
	anesthesiaType?: AnesthesiaType,
	active?: boolean,
	future?: boolean
): { loading: boolean; surgeries: Paginated<Surgery> | undefined; refresh: () => Promise<void> } {
	const user = useUser();
	const surgeries = useComputedObservable(() => hospitalService.surgeries.get().get(pageOptions.page), [
		user,
		pageOptions,
		sortOption,
	]);
	const [loading, setLoading] = useState(true);
	const fetchSurgeries = useCallback(async () => {
		try {
			if (user) {
				setLoading(true);
				const implicitSortOption: SortOption = { by: "surgeryDate", order: "ASC" };
				await hospitalService.fetchSurgeries(
					pageOptions,
					selectedPractitioner,
					sortOption ? [sortOption, implicitSortOption] : [implicitSortOption],
					search,
					anesthesiaType,
					active,
					future
				);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [pageOptions, sortOption, user, search, anesthesiaType, active, future]);

	useEffect(() => {
		fetchSurgeries();
	}, [fetchSurgeries]);
	return {
		loading,
		surgeries,
		refresh: fetchSurgeries,
	};
}

export function usePractitioner(
	practitionerId: string | undefined,
	hospitalId: string | undefined
): { practitioner: Practitioner | null } {
	const user = useUser();
	const practitioner = useComputedObservable(() => hospitalService.practitioner.get(), []);
	const fetchPractitioner = useCallback(async () => {
		try {
			if (user && practitionerId && hospitalId) {
				await hospitalService.fetchPractitioner(practitionerId as string);
			}
		} catch (e) {
			console.log(e);
		}
	}, [user, practitionerId]);
	useEffect(() => {
		fetchPractitioner().then();
	}, [fetchPractitioner]);
	return {
		practitioner,
	};
}

export function useHospitalSurgery(surgeryId: string): { loading: boolean; surgery: Surgery | undefined } {
	const user = useUser();
	const surgery = useComputedObservable(() => hospitalService.surgeryByIds.get().get(surgeryId), [user, surgeryId]);
	const [loading, setLoading] = useState(true);
	const fetchSurgeries = useCallback(async () => {
		try {
			if (user && surgeryId) {
				setLoading(true);
				await hospitalService.fetchSurgery(surgeryId);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [user, surgeryId]);

	useEffect(() => {
		fetchSurgeries();
	}, [fetchSurgeries]);

	return {
		loading,
		surgery,
	};
}

export function useModules(hospitalId: string | undefined) {
	const user = useUser();
	const modules = useComputedObservable(() => hospitalService.modulesByHospitalId.get().get(hospitalId ?? ""), [
		hospitalId,
	]);
	const fetchModules = useCallback(async () => {
		try {
			if (user?.type == UserType.Practitioner && hospitalId) {
				await hospitalService.fetchSurgeryHospitalModulesPractitioner(hospitalId);
			} else if (user?.type == UserType.Patient && hospitalId) {
				await hospitalService.fetchSurgeryHospitalModulesPatient(hospitalId);
			} else if (user?.type == UserType.Hospital && hospitalId) {
				await hospitalService.fetchSurgeryHospitalModulesHospital(hospitalId);
			} else if (user?.type == UserType.Secretary && hospitalId) {
				await hospitalService.fetchSurgeryHospitalModulesSecretary(hospitalId);
			}
		} catch (e) {
			console.log(e);
		}
	}, [user, hospitalId]);
	useEffect(() => {
		fetchModules().then();
	}, [fetchModules]);
	return modules;
}

export function useSecretaryModules(hospitalId: string | undefined) {
	const user = useUser();
	const modules = useComputedObservable(() => hospitalService.modulesByHospitalId.get().get(hospitalId ?? ""), [
		hospitalId,
	]);
	const fetchModules = useCallback(async () => {
		try {
			if (user && hospitalId) {
				await hospitalService.fetchSurgeryHospitalModulesSecretary(hospitalId);
			}
		} catch (e) {
			console.log(e);
		}
	}, [user, hospitalId]);
	useEffect(() => {
		fetchModules().then();
	}, [fetchModules]);

	return modules;
}

export function usePractitioners(hospitalId: string | undefined) {
	const user = useUser();
	const practitioners = useComputedObservable(() => hospitalService.practitioners.get().get(hospitalId ?? ""), [
		hospitalId,
	]);
	const fetchPractitioners = useCallback(async () => {
		try {
			if (user && hospitalId) {
				await hospitalService.fetchPractitionersByHospital(hospitalId);
			}
		} catch (e) {
			console.log(e);
		}
	}, [user, hospitalId]);
	useEffect(() => {
		fetchPractitioners().then();
	}, [fetchPractitioners]);

	return practitioners;
}

export function usePractitionersDpi(hospitalId: string | undefined) {
	const practitionersDpi = useComputedObservable(() => hospitalService.practitionersDpi.get().get(hospitalId ?? ""), [
		hospitalId,
	]);
	const fetchPractitionersDpi = useCallback(async () => {
		try {
			if (hospitalId) {
				await hospitalService.fetchPractitionersDpiByHospital(hospitalId);
			}
		} catch (e) {
			console.log(e);
		}
	}, [hospitalId]);
	useEffect(() => {
		fetchPractitionersDpi().then();
	}, [fetchPractitionersDpi]);

	return practitionersDpi;
}

export function useHospitalUserProfile() {
	return useObservable(hospitalService.profile);
}

export function useCCAMDatabaseHospitalUser(): { ccamList: CCAM[] } {
	const user = useUser();
	const ccamList = useComputedObservable(() => hospitalService.ccams.get(), []);
	const fetchCCAMS = useCallback(async () => {
		try {
			if (user) {
				await hospitalService.fetchCCAMS();
			}
		} catch (e) {
			console.log("Query fetch DocCustomForm aborted");
		}
	}, []);
	useEffect(() => {
		fetchCCAMS().then();
	}, []);
	return { ccamList };
}