import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fullName, fullNameHPU } from "domain/utils";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { colors, mobile, row, sizes, Spacer, stack } from "style";
import { useI18n } from "utils/i18n";
import { AccountSelector } from "./account-selector";
import { UserType } from "../../domain/user-type";
import { hpuService, useHpuProfile } from "../../services/hpu-service";
import { createModal } from "../../services/modal-service";
import { IdeaModal } from "../modals/idea-modal";
import { Bulb, ButtonSupport, ToolTipIdeas } from "./practitioner-nav-bar";

export const HpuNavBar: React.FC<{ className?: string }> = ({ className }) => {
	const location = useLocation();
	const { format } = useI18n();
	const profile = useHpuProfile();
	const [showToolTip, setShowToolTip] = useState<boolean>(false);
	useEffect(() => {
		hpuService.fetchProfile().then();
	}, []);
	return (
		<Container className={className}>
			<LogoContainer>
				<LogoSophi src="/images/logo.svg" />
				<span>{format("practitioner.header.logo")}</span>
			</LogoContainer>
			<Spacer />
			<Tab
				to="/hpu/dashboard-practitioners"
				selected={
					!!location.pathname.match("/hpu/dashboard-practitioners") ||
					!!location.pathname.match("/hpu/practitioner/[a-zA-Z0-9]+$")
				}
			>
				{format("hpu.header.practitioners")}
			</Tab>
			<Tab
				to="/hpu/dashboard-patients-accepted"
				selected={
					!!location.pathname.match("/hpu/dashboard-patients-accepted") ||
					!!location.pathname.match("/hpu/dashboard-patients-inprogress") ||
					!!location.pathname.match(
						"/hpu/dashboard-patients-archived"
					) /*||
					!!location.pathname.match("/hpu/patient/[a-zA-Z0-9]+$")*/
				}
			>
				Patients
			</Tab>
			<Spacer />
			<AccountSelector name={fullNameHPU(profile)} accountType={UserType.Hpu}  showAddDocCustom showTuto={true}/>
			<div
				onMouseOver={() => setShowToolTip(true)}
				onMouseOut={() => {
					setShowToolTip(false);
				}}
				onClick={() => {
					createModal(({ onClose }) => <IdeaModal onClose={onClose} hpuProfile={profile} />);
				}}
			>
				<ButtonSupport>
					<Bulb />
				</ButtonSupport>
				<ToolTipIdeas>{format("ideas.box.button.tooltip")}</ToolTipIdeas>
			</div>
		</Container>
	);
};

const Container = styled.div`
	${row("L", "flex-start", "center")};
	padding: ${sizes.L};
	background-color: ${colors.white};
	border-radius: 26px;
	box-shadow: 0 22px 64px 0 rgba(0, 0, 0, 0.05);
	font-size: 15px;
	font-weight: bold;

	@media ${mobile} {
		${stack("S", "center", "center")}
	}
`;

const LogoContainer = styled.div`
	${row(5, "flex-start", "center")}
	color: ${colors.black};
`;

const LogoSophi = styled.img`
	width: 29px;
`;

const Tab = styled(Link)<{ selected: boolean }>`
	display: block;
	position: relative;
	cursor: pointer;
	color: ${colors.black};
	opacity: 0.3;

	${({ selected }) =>
		selected &&
		css`
			opacity: 1;
			:after {
				position: absolute;
				bottom: -15px;
				left: 50%;
				transform: translateX(-50%);
				content: "";
				height: 6px;
				width: 6px;
				border-radius: 3px;
				background-color: ${colors.pink};

				@media ${mobile} {
					left: -15px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		`}
`;
