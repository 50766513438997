import styled from "@emotion/styled";
import { DocumentErrorIcon } from "@assets/icons/document-error.svg";
import { Button } from "components/button";
import React, { useCallback, useState } from "react";
import { ModalCardContainer, ModalCloseIcon } from "services/modal-service";
import { practitionerService } from "services/practitioner-service";
import { colors, row, stack } from "style";
import { useI18n } from "utils/i18n";
import { motion } from "framer-motion";
import { secretaryService } from "../../services/secretary-service";
import { hpState, Surgery, VisitType } from "../../domain/surgery";
import { AnesthesiaType } from "../../domain/anesthesia";
import { useUser } from "../../services/auth-service";
import { patientService } from "../../services/patient-service";
import { hospitalService } from "../../services/hospital-service";

interface ConfirmSecretaryModalProps {
	onClose: (patientId: string | null) => void;
	surgeryId: string;
	practitionerId?: string | null;
	surgery: Surgery;
	anesthesiaType?: AnesthesiaType | null | undefined;
	hciPatientId?: string | null | undefined;
	hciVisitId?: string | null | undefined;
	hospitalId?: string | null | undefined;
	visitType?: VisitType | null | undefined;
	visitStartDate?: string | null | undefined;
	visitEndDate?: string | null | undefined;
	surgeryDate?: string | null | undefined;
	classificationCode?: string | null | undefined;
	laterality?: string | null | undefined;
	healthcareProviderId?: string | null | undefined;
	needHealthcareProvider: boolean | undefined;
	healthcareProviderReason?: string | null | undefined;
	needAld?: boolean | undefined;
	needWorkAccident?: boolean | undefined;
	agreementSocialSecurity?: boolean | undefined;
}

export const ConfirmSurgeryEditModal: React.FC<ConfirmSecretaryModalProps> = ({
	onClose,
	surgeryId,
	practitionerId,
	surgery,
	anesthesiaType,
	hciPatientId,
	hciVisitId,
	hospitalId,
	visitType,
	visitStartDate,
	visitEndDate,
	surgeryDate,
	classificationCode,
	laterality,
	healthcareProviderId,
	needHealthcareProvider,
	healthcareProviderReason,
	needAld,
	needWorkAccident,
	agreementSocialSecurity,
}) => {
	const { format } = useI18n();
	const [loading, setLoading] = useState(false);
	const user = useUser();
	if (practitionerId == undefined) {
		practitionerId = surgery.practitionerId;
	}
	const save = useCallback(async () => {
		try {
			setLoading(true);
			if (anesthesiaType != undefined && surgery.anesthesiaType != anesthesiaType) {
				surgery.anesthesiaType = anesthesiaType;
			}

			if (hciPatientId != undefined && surgery.hciPatientId != hciPatientId) {
				surgery.hciPatientId = hciPatientId;
			}

			if (hciVisitId != undefined && surgery.hciVisitId != hciVisitId) {
				surgery.hciVisitId = hciVisitId;
			}

			if (hospitalId != undefined && surgery.hospitalId != hospitalId) {
				surgery.hospitalId = hospitalId;
			}

			if (visitType != undefined && surgery.visitType != visitType) {
				surgery.visitType = visitType;
			}

			if (visitStartDate != undefined && surgery.visitStartDate != visitStartDate) {
				surgery.visitStartDate = visitStartDate;
			}

			if (visitEndDate != undefined && surgery.visitEndDate != visitEndDate) {
				surgery.visitEndDate = visitEndDate;
			}

			if (surgeryDate != undefined && surgery.surgeryDate != surgeryDate) {
				surgery.surgeryDate = surgeryDate;
			}

			if (classificationCode != undefined && surgery.classificationCode != classificationCode) {
				surgery.classificationCode = classificationCode;
			}

			if (laterality != undefined && surgery.laterality != laterality) {
				surgery.laterality = laterality;
			}

			if (healthcareProviderId != undefined && surgery.healthcareProviderId != healthcareProviderId) {
				surgery.healthcareProviderId = healthcareProviderId;
			}
			if (healthcareProviderReason != undefined && surgery.healthcareProviderReason != healthcareProviderReason) {
				surgery.healthcareProviderReason = healthcareProviderReason;
			}

			if (needHealthcareProvider == false) {
				surgery.healthcareProviderId = null;
				surgery.healthcareProviderReason = "";
			}

			if (needAld != undefined && needAld != !!surgery.needAld) {
				surgery.needAld = +needAld;
			}
			if (needWorkAccident != undefined && needWorkAccident != !!surgery.needWorkAccident) {
				surgery.needWorkAccident = +needWorkAccident;
			}
			if (agreementSocialSecurity != undefined && agreementSocialSecurity != !!surgery.agreementSocialSecurity) {
				surgery.agreementSocialSecurity = +agreementSocialSecurity;
			}

			if (user?.type == "patient" && practitionerId as string) {
				await patientService.updateSurgery(surgeryId, practitionerId as string, surgery);
				onClose(null);
			} else if (user?.type == "hospital" && practitionerId as string) {
				await hospitalService.updateSurgery(surgeryId, practitionerId as string, surgery);
				onClose(null);
			} else if (practitionerId && user?.type == "secretary") {
				await secretaryService.updateSurgery(surgeryId, practitionerId as string, surgery);
				onClose(null);
			} else if (user?.type == "practitioner") {
				await practitionerService.updateSurgery(surgeryId, surgery);
				onClose(null);
			}
		} catch (e: any) {
			throw e.message;
		} finally {
			setLoading(false);
		}
	}, [loading]);
	return (
		<ModalContainer>
			<form
				onSubmit={e => {
					e.preventDefault();
					save();
				}}
			>
				<ModalCloseIcon onClick={() => onClose(null)} />
				<Content>
					<DocumentsMessageContainer>
						<ColorDocumentErrorIcon color={colors.orange} />
						<Title>{format("secretary.modal.confirm.patient.title")}</Title>
					</DocumentsMessageContainer>
					<Text>
						<div dangerouslySetInnerHTML={{ __html: format("secretary.modal.confirm.patient.warning") }}></div>
					</Text>
					<Text>{format("secretary.modal.confirm.patient.infos")}</Text>
					<Text>{format("secretary.modal.confirm.patient.confirmation")}</Text>
					<Buttons>
						<Button loading={loading}>{format("secretary.modal.confirm.patient.confirmation.button")}</Button>
						<Button secondary onClick={() => onClose(null)}>
							{format("secretary.modal.confirm.patient.cancel.button")}
						</Button>
					</Buttons>
				</Content>
			</form>
		</ModalContainer>
	);
};

const ModalContainer = styled(ModalCardContainer)`
	padding: 30px;
	${row(0, "center", "center")};
`;

const DocumentsMessageContainer = styled.div`
	${row("S", "flex-start", "flex-start")};
`;

const ColorDocumentErrorIcon = styled(DocumentErrorIcon)<{ color: string }>`
	width: 32px;
	height: 32px;
	path {
		fill: ${props => props.color};
	}
`;
const Content = styled.div`
	${stack("M")};
	max-width: 500px;
`;
const Title = styled(motion.div)`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;

const Buttons = styled.div`
	${row("S", "flex-end", "flex-end")}
`;

const Text = styled(motion.span)<{ textColor?: string }>`
	font-size: 13px;
	color: ${props => props.textColor ?? "black"};
`;
